import {
  Document,
  DocumentImport,
  Report,
  ScatterMatrix,
  Time,
  Edit,
} from '@carbon/icons-react'
import { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useOutletContext } from 'react-router-dom'
import SpecificationActions from './SpecificationActions.tsx'
import styles from './SpecificationPage.module.css'
import * as api from '../../api/v2/specifications.ts'
import ItarIcon from '../../assets/ItarIcon'
import Chip from '../../components/chip'
import EditableSpan from '../../components/editable-span/EditableSpan'
import HoverableSpan from '../../components/hoverable/HoverableSpan.tsx'
import Tag, { TAG_COLORS } from '../../components/tag'
import { useAttributesContext } from '../../context/AttributesContext'
import { useAuth } from '../../context/AuthContext.tsx'
import { useBreadcrumbsContext } from '../../context/BreadcrumbsContext.tsx'
import { MODAL_ID_NEW_SPECIFICATION } from '../../context/GlobalModalContext.tsx'
import { useSpecificationContext } from '../../context/SpecificationContext'
import { useModals } from '../../hooks/useModals'
import usePrintPDF from '../../hooks/usePrintPDF.ts'
import { monthDayYear } from '../../lib/date'
import { getDismissedModals } from '../../lib/localstorage.ts'
import {
  EntityReviewReviewerStatus,
  EntityReviewStatus,
} from '../../types/api/v2/entity.ts'
import { ReportSource } from '../../types/enums.ts'

const SpecificationPage = () => {
  const {
    specification,
    revision,
    document,
    isHistoricalRevision,
    pendingReview,
    pendingReviewReviewers,
    updateSpecificationIdentifier,
    updateSpecificationName,
    contentIsEditable,
    canEditSpecificationIdentifier,
  } = useSpecificationContext()

  const { getProgramById } = useAttributesContext()
  const { userDetails, userIsOwner } = useAuth()
  const userId = userDetails?.id
  const { pathname } = useLocation()

  const { isPrinting, onPrint, printRef } = usePrintPDF({
    documentTitle: specification.name || 'Untitled',
  })

  const { openSpecificationHistoryModal, openSpecificationPdfViewerModal } =
    useModals()

  const { setBreadcrumbs } = useBreadcrumbsContext()
  const { scrollerRef } = useOutletContext<{
    scrollerRef: React.RefObject<HTMLDivElement>
  }>()

  const [externalOriginVersion, setExternalOriginVersion] = useState<string>('')

  useEffect(() => {
    window.document.title = 'Specifications'
  }, [])

  useEffect(() => {
    if (specification) {
      setBreadcrumbs([
        { text: 'Specifications', linkTo: '/specifications' },
        {
          text: specification.name,
          linkTo: `/specifications/${specification.id}/document`,
        },
      ])
    }
  }, [specification, setBreadcrumbs])

  useEffect(() => {
    if (!specification.external) {
      return
    }

    const getExternalOriginVersion = async () => {
      try {
        const origin = await api.getSpecificationOrigin(specification.id)
        setExternalOriginVersion(origin.version)
      } catch (error) {
        console.error('Unable to get specification origin', error)
      }
    }
    getExternalOriginVersion()
  }, [specification.external, specification.id])

  const program = getProgramById(specification.program || '')

  const isExternal = specification.external
  const version = isExternal ? externalOriginVersion : revision?.version

  const pendingApproval =
    !isExternal && pendingReview?.status === EntityReviewStatus.PENDING

  const userIsReviewer =
    pendingReviewReviewers.find((reviewer) => reviewer.userId === userId)
      ?.status === EntityReviewReviewerStatus.PENDING

  const allReviewersApproved =
    pendingReviewReviewers.length > 0 &&
    pendingReviewReviewers.every(
      (reviewer) => reviewer.status === EntityReviewReviewerStatus.APPROVED,
    )

  const userCanReleaseReview =
    userIsOwner(specification.id) && allReviewersApproved

  const viewPdf = () => {
    if (document?.sourceAssetId) {
      openSpecificationPdfViewerModal({
        specificationId: specification.id,
        assetId: document.sourceAssetId,
        uploadedOn: document.createdOn,
      })
    }
  }

  const { openNewSpecificationModal } = useModals()

  const [displayNewSpecModal, setDisplayNewSpecModal] = useState(
    canEditSpecificationIdentifier &&
      !specification.specificationIdentifier &&
      !(getDismissedModals()[MODAL_ID_NEW_SPECIFICATION] || []).includes(
        specification.id,
      ),
  )

  const handleSpecIdentifierClick = () => {
    if (canEditSpecificationIdentifier) {
      setDisplayNewSpecModal(true)
    }
  }

  useEffect(() => {
    if (displayNewSpecModal) {
      openNewSpecificationModal({
        updateSpecificationIdentifier,
        specificationId: specification.id,
        specificationIdentifier: specification.specificationIdentifier,
      })
      setDisplayNewSpecModal(false)
    }
  }, [
    displayNewSpecModal,
    openNewSpecificationModal,
    specification.id,
    updateSpecificationIdentifier,
    specification.specificationIdentifier,
  ])

  return (
    <>
      <div ref={printRef} className={styles.headerSection}>
        <div className={styles.leftHeader}>
          <div className={styles.specIdentifier}>
            <HoverableSpan
              text={
                specification?.specificationIdentifier || 'Add Document Number'
              }
              readOnly={!canEditSpecificationIdentifier}
              hoverContent={<Edit size={14} className={styles.icon} />}
              onClick={handleSpecIdentifierClick}
            />
          </div>
          <div className={styles.nameRow}>
            <EditableSpan
              readOnly={!contentIsEditable}
              onValueChange={updateSpecificationName}
              value={specification?.name}
              placeholder="Untitled"
              style={{ fontSize: '20px', color: '#000' }}
            />
            {version && (
              <>
                {isExternal ? (
                  <div className={styles.version}>{version}</div>
                ) : (
                  <button
                    onClick={() =>
                      openSpecificationHistoryModal(specification.id)
                    }
                    className={styles.version}
                  >
                    V{version}
                  </button>
                )}
              </>
            )}
            {revision?.exportControlled && (
              <div className={styles.itar}>
                <ItarIcon />
                <div className={styles.tooltip}>
                  This specification contains export controlled data.
                  <br />
                  Please follow your organization's security guidelines.
                </div>
              </div>
            )}
          </div>
          {program && (
            <Tag
              text={program.name}
              color={{
                fontColor: program.metadata.STYLES.COLOR_FONT,
                backgroundColor: program.metadata.STYLES.COLOR_BG,
              }}
            />
          )}
          {isHistoricalRevision && (
            <Tag text="OLDER VERSION" color={TAG_COLORS.red2NoHover} />
          )}
        </div>
        <div className={styles.rightHeader}>
          <div className={styles.info}>
            {document?.sourceAssetId && (
              <button className={styles.viewPdf} onClick={viewPdf}>
                View original
              </button>
            )}
            {isExternal ? (
              <>
                <Tag text="External" />
                <div className={styles.timestampContainer}>
                  <DocumentImport size={16} />
                  <div className={styles.dateTime}>
                    {monthDayYear(specification.createdOn)}
                  </div>
                </div>
              </>
            ) : (
              <>
                <Chip variant="status" value={revision.status} enableLockIcon />
                <button
                  onClick={() =>
                    openSpecificationHistoryModal(specification.id)
                  }
                >
                  <div className={styles.dateTime}>
                    <Time size={16} />
                    {monthDayYear(revision.lastModifiedOn)}
                  </div>
                </button>
              </>
            )}
          </div>
          <div className={styles.actionsContainer}>
            {!isExternal && pendingApproval && (
              <div className={styles.textDetail}>
                {userIsReviewer &&
                  'Your review is requested on this specification'}
                {userCanReleaseReview && 'Approve release of specification'}
              </div>
            )}
            {!isPrinting && (
              <SpecificationActions
                pendingApproval={pendingApproval}
                userIsReviewer={userIsReviewer}
                userCanReleaseReview={userCanReleaseReview}
                isExternal={isExternal}
                onExport={onPrint}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.tabs}>
        <div className={styles.leftTabs}>
          <Link to={`./document`} draggable="false">
            <div
              className={`${styles.tab} ${
                pathname.includes('document') ? styles.active : ''
              }`}
            >
              <Document size={24} />
              Document
            </div>
          </Link>
          <Link to={`./matrix`} draggable="false">
            <div
              className={`${styles.tab} ${
                pathname.includes('matrix') ? styles.active : ''
              }`}
            >
              <ScatterMatrix size={24} />
              Matrix
            </div>
          </Link>
        </div>
        <div className={styles.rightTabs}>
          <Link
            to={`/reports?sources=${ReportSource.SpecificationRequirementV1}&specificationIds=${specification.id}`}
          >
            <div className={styles.tab}>
              <Report size={24} />
              Reports
            </div>
          </Link>
        </div>
      </div>
      <Outlet context={{ scrollerRef }} />
    </>
  )
}

export default SpecificationPage
