import api from './api.ts'
import { AttributeValueResponse } from './attributes.ts'
import { BlockData, BlockType } from './blocks.ts'
import { RequirementData } from './requirements.ts'
import { Project, SharedSpecificationSnapshotData } from './sharedWorkspaces.ts'
import {
  EntityType,
  SpecificationSnapshotReviewStatus,
} from '../../types/enums.ts'

const createUrl = (
  specificationId?: string,
  snapshotId?: string,
  reviewId?: string,
  requirementId?: string,
) =>
  `/api/v2/specifications${specificationId ? `/${specificationId}` : ''}${
    snapshotId ? `/snapshots/${snapshotId}` : ''
  }${reviewId ? `/reviews/${reviewId}` : ''}${
    requirementId ? `/requirements/${requirementId}` : ''
  }`

export interface ShareSnapshotResponse {
  id: string
  workspaceId: string
  projectId: string
}

export const shareSpecification: (
  specificationId: string,
  tenantId: string,
  projectId: string,
) => Promise<ShareSnapshotResponse> = (specificationId, tenantId, projectId) =>
  api.post(`${createUrl(specificationId)}/share`, {
    body: { tenantId, projectId },
  })

export type SharedSpecificationAttributeValue = Omit<
  AttributeValueResponse,
  'entityCount'
>

interface SharedSpecificationCustomAttribute {
  /** @format guid */
  id: string
  name: string
  entityTypes: EntityType[]
  values: SharedSpecificationAttributeValue[]
}

export interface SharedBlock<D extends BlockData> {
  /** @format guid */
  id: string
  type: BlockType
  data: D
}

export interface SharedSpecificationRequirement
  extends Omit<RequirementData, 'data' | 'exportControlled'> {
  /** @format guid */
  id: string
  identifier: number
}

interface SharedSpecificationContent {
  /** @format guid */
  id: string
  customAttributes: SharedSpecificationCustomAttribute[]
  documentBlocks: SharedBlock<BlockData>[]
  requirements: SharedSpecificationRequirement[]
  revision: {
    /** @format guid */
    id: string
    version: number
  }
  specification: {
    /** @format guid */
    id: string
    name: string
    identifier: string
    externalOrigin: {
      organizationName: string
      version: string | null
    } | null
  }
  version: number
}

export interface SharedSpecificationSnapshot
  extends SharedSpecificationSnapshotData {
  contents: SharedSpecificationContent
}

export const getSharedSpecificationSnapshot: (
  specificationId: string,
  snapshotId: string,
) => Promise<SharedSpecificationSnapshot> = async (
  specificationId,
  snapshotId,
) => {
  const snapshot = await api.get(createUrl(specificationId, snapshotId))
  return {
    ...snapshot,
    createdOn: new Date(snapshot.createdOn),
  }
}

export interface SharedSpecificationSnapshotReviewRequirement {
  /** @format guid */
  requirementId: string
  complete: boolean
}

export interface SharedSpecificationSnapshotReview {
  /** @format guid */
  id: string
  name: string
  status: SpecificationSnapshotReviewStatus
  requirements: Record<string, SharedSpecificationSnapshotReviewRequirement>
  viewerTenantName: string
  specificationName: string
  project: Project
  workspaceId: string
}

export const createSharedSpecificationSnapshotReview: (
  specificationId: string,
  snapshotId: string,
) => Promise<SharedSpecificationSnapshotReview> = async (
  specificationId,
  snapshotId,
) => {
  const { review } = await api.post(
    `${createUrl(specificationId, snapshotId)}/reviews`,
    {
      body: {},
    },
  )
  return review
}

export const getSharedSpecificationSnapshotReview = async (
  specificationId: string,
  snapshotId: string,
  reviewId: string,
): Promise<SharedSpecificationSnapshotReview> =>
  (
    await api.get(
      `${createUrl(specificationId, snapshotId)}/reviews/${reviewId}`,
    )
  ).review

export const getCurrentSharedSpecificationSnapshotReview: (
  specificationId: string,
  snapshotId: string,
) => Promise<SharedSpecificationSnapshotReview> = async (
  specificationId,
  snapshotId,
) => {
  const { review } = await api.get(
    `${createUrl(specificationId, snapshotId)}/reviews/current`,
  )
  return review
}

export const updateSharedSpecificationSnapshotReview: (
  specificationId: string,
  snapshotId: string,
  reviewId: string,
  update: Partial<SharedSpecificationSnapshotReview>,
) => Promise<SharedSpecificationSnapshotReview> = async (
  specificationId,
  snapshotId,
  reviewId,
  update,
) => {
  const { review } = await api.patch(
    createUrl(specificationId, snapshotId, reviewId),
    {
      body: update,
    },
  )
  return review
}

export const completeSharedSpecificationSnapshotReview: (
  specificationId: string,
  snapshotId: string,
  reviewId: string,
) => Promise<SharedSpecificationSnapshotReview> = async (
  specificationId,
  snapshotId,
  reviewId,
) => {
  const { review } = await api.patch(
    `${createUrl(specificationId, snapshotId, reviewId)}/complete`,
  )
  return review
}

export const updateSharedSpecificationSnapshotReviewRequirement: (
  specificationId: string,
  snapshotId: string,
  reviewId: string,
  requirementId: string,
  complete: boolean,
) => Promise<{ id: string; complete: boolean }> = (
  specificationId,
  snapshotId,
  reviewId,
  requirementId,
  complete,
) =>
  api.patch(createUrl(specificationId, snapshotId, reviewId, requirementId), {
    body: { complete },
  })

/**
 * Snapshot Review Requirement Comments
 */

export interface ReviewRequirementComment {
  id: string
  body: string
  createdBy: {
    firstName: string
    lastName: string
  }
  createdByUserId: string
  createdOn: Date
}

export const getReviewRequirementComments = async (
  specificationId: string,
  snapshotId: string,
  reviewId: string,
): Promise<{
  requirementComments: Record<string, ReviewRequirementComment[]>
}> => {
  const reviewRequirementComments = (await api.get(
    `${createUrl(
      specificationId,
    )}/snapshots/${snapshotId}/reviews/${reviewId}/comments`,
  )) as {
    requirementComments: Record<
      string,
      (Omit<ReviewRequirementComment, 'createdOn'> & { createdOn: string })[]
    >
  }

  const commentsWithDates = Object.fromEntries(
    Object.entries(reviewRequirementComments.requirementComments).map(
      ([requirementId, comments]) => {
        return [
          requirementId,
          comments.map(
            (comment) =>
              ({
                ...comment,
                createdOn: new Date(comment.createdOn),
              }) as ReviewRequirementComment,
          ),
        ]
      },
    ),
  )

  return {
    ...reviewRequirementComments,
    requirementComments: commentsWithDates,
  }
}

export const addReviewRequirementComment = async (
  specificationId: string,
  snapshotId: string,
  reviewId: string,
  requirementId: string,
  comment: string,
): Promise<ReviewRequirementComment> => {
  const commentResponse = await api.post(
    `${createUrl(
      specificationId,
    )}/snapshots/${snapshotId}/reviews/${reviewId}/requirements/${requirementId}/comments`,
    { body: { body: comment } },
  )

  return {
    ...commentResponse,
    createdOn: new Date(commentResponse.createdOn),
  }
}

export const deleteReviewRequirementComment = (
  specificationId: string,
  snapshotId: string,
  reviewId: string,
  requirementId: string,
  commentId: string,
): Promise<{ id: string }> =>
  api.delete(
    `${createUrl(
      specificationId,
      snapshotId,
      reviewId,
      requirementId,
    )}/comments/${commentId}`,
  )
