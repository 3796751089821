import { DocumentAdd, Edit } from '@carbon/icons-react'
import { useCallback, useState } from 'react'
import Modal from './index.tsx'
import styles from './NewSpecificationModal.module.css'

import { MODAL_ID_NEW_SPECIFICATION } from '../../context/GlobalModalContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import * as ls from '../../lib/localstorage.ts'
import { ApiError } from '../../types/errors.ts'
import Button, { BUTTON_COLORS } from '../button'
import { TextInput } from '../input/TextInput.tsx'

export interface NewSpecificationModalProps {
  updateSpecificationIdentifier: (
    val: string,
  ) => Promise<ApiError | undefined | void>
  specificationId: string
  specificationIdentifier?: string
}
const NewSpecificationModal = (
  props: NewSpecificationModalProps & {
    closeModal: () => void
  },
) => {
  const {
    closeModal,
    updateSpecificationIdentifier,
    specificationId,
    specificationIdentifier = '',
  } = props
  const MAX_SPEC_IDENTIFIER_LENGTH = 15

  const [errorMsg, setErrorMsg] = useState<string>()
  const [newSpecIdentifier, setNewSpecIdentifier] = useState<string>(
    specificationIdentifier,
  )

  const updateNewSpecModalInLS = (specificationId: string) => {
    const dismissedModals = ls.getDismissedModals()
    const newSpecModalsDismissed =
      dismissedModals[MODAL_ID_NEW_SPECIFICATION] || []

    if (!newSpecModalsDismissed.includes(specificationId)) {
      ls.setDismissedModals({
        ...dismissedModals,
        [MODAL_ID_NEW_SPECIFICATION]: [
          ...newSpecModalsDismissed,
          specificationId,
        ],
      })
    }
  }

  const onClickOutside = useCallback(() => {
    updateNewSpecModalInLS(specificationId)
    closeModal()
  }, [closeModal, specificationId])

  const containerRef = useClickOutside(onClickOutside)

  return (
    <Modal
      innerRef={containerRef}
      title={
        specificationIdentifier
          ? 'Update Specification Number'
          : 'New Specification'
      }
      onClose={() => {
        updateNewSpecModalInLS(specificationId)
        closeModal()
      }}
      icon={specificationIdentifier ? <Edit /> : <DocumentAdd />}
    >
      {specificationIdentifier ? (
        <div className={styles.text}>
          Update the specification document number below.
        </div>
      ) : (
        <>
          <div className={styles.text}>
            Before getting started on a specification, set a number sequence for
            your requirements.
          </div>
          <div className={styles.text}>
            This can be updated at any point before specification release by
            clicking on the number above the specification title.
          </div>
        </>
      )}
      <div className={styles.newSpecIdentifier}>
        <span>Number</span>
        <TextInput
          className={styles.input}
          placeholder="00-00-1"
          value={newSpecIdentifier}
          setValue={(val) => {
            setErrorMsg(undefined)
            setNewSpecIdentifier(val)
          }}
          focusOnLoad
          maxLength={MAX_SPEC_IDENTIFIER_LENGTH}
        />
      </div>
      {errorMsg && <div className={styles.error}>{errorMsg} </div>}
      <div className={styles.ctaWrapper}>
        <Button
          className={styles.cta}
          text="Set number"
          color={BUTTON_COLORS.PRIMARY}
          disabled={!newSpecIdentifier || !!errorMsg}
          onClick={async (e) => {
            e.preventDefault()

            if (newSpecIdentifier) {
              const error = (await updateSpecificationIdentifier(
                newSpecIdentifier,
              )) as ApiError & { status: number }

              if (!error) {
                updateNewSpecModalInLS(specificationId)
                closeModal()
              } else if (error?.status === 409) {
                setErrorMsg(
                  'Document Number is already in use. Please provide a unique Document Number.',
                )
              } else {
                setErrorMsg('An error occurred. Please try again.')
              }
            }
          }}
        />
      </div>
    </Modal>
  )
}

export default NewSpecificationModal
