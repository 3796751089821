import { Add } from '@carbon/icons-react'
import { PostHogFeature } from 'posthog-js/react'
import { useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styles from './Matrix.module.css'
import MatrixColumns, {
  MatrixColumnLabels,
  MatrixColumnPrettyLabels,
  PublicSpecMatrixColumns,
} from './MatrixColumns'
import * as requirementsApi from '../../api/v2/requirements.ts'
import { RevisionStatus } from '../../api/v2/revisions.ts'
import { MatrixColumnId } from '../../api/v2/specifications.ts'
import Button from '../../components/button'
import FilterDropdown, {
  AppliedFilters,
  useFilterState,
} from '../../components/filter-menu'
import { FilterKey } from '../../components/filter-menu/types'
import useResizableColumns from '../../components/table/useResizableColumns.ts'
import VirtualTable from '../../components/table/VirtualTable'
import { useAuth } from '../../context/AuthContext.tsx'
import { useMatrixContext } from '../../context/MatrixContext.tsx'
import { useSpecificationContext } from '../../context/SpecificationContext'
import { useModals } from '../../hooks/useModals.ts'
import { useReports } from '../../hooks/useReports.ts'
import { EvidenceType, RequirementStatus } from '../../types/enums.ts'
import DisplayFilter, { FilterGroup } from '../search/display-filter'

const FILTER_KEYS = [
  FilterKey.RequirementType,
  FilterKey.RequirementStatus,
  FilterKey.Compliance,
  FilterKey.ValidationMethod,
  FilterKey.VerificationMethod,
]

const FILTER_GROUP_0 = {
  filters: [
    { label: MatrixColumnId.ID, prettyLabel: MatrixColumnLabels.ID },
    {
      label: MatrixColumnId.SectionNumber,
      prettyLabel: MatrixColumnLabels.SectionNumber,
    },
    {
      label: MatrixColumnId.RequirementName,
      prettyLabel: MatrixColumnLabels.RequirementName,
    },
    {
      label: MatrixColumnId.ShallStatement,
      prettyLabel: MatrixColumnLabels.ShallStatement,
    },
    {
      label: MatrixColumnId.Compliance,
      prettyLabel: MatrixColumnLabels.Compliance,
    },
    {
      label: MatrixColumnId.ComplianceNotes,
      prettyLabel: MatrixColumnLabels.ComplianceNotes,
    },
    {
      label: MatrixColumnId.Rationale,
      prettyLabel: MatrixColumnLabels.Rationale,
    },
    {
      label: MatrixColumnId.ExternalNumber,
      prettyLabel: MatrixColumnLabels.ExternalNumber,
    },
    { label: MatrixColumnId.Status, prettyLabel: MatrixColumnLabels.Status },
    { label: MatrixColumnId.Type, prettyLabel: MatrixColumnLabels.Type },
  ],
}

const FILTER_GROUP_1 = {
  spaceBetween: true,
  filters: [
    {
      label: MatrixColumnId.ParentRequirements,
      prettyLabel: MatrixColumnLabels.ParentRequirements,
    },
    {
      label: MatrixColumnId.ChildRequirements,
      prettyLabel: MatrixColumnLabels.ChildRequirements,
    },
    {
      label: MatrixColumnId.ParentRequirementName,
      prettyLabel: MatrixColumnLabels.ParentRequirementName,
    },
    {
      label: MatrixColumnId.ChildRequirementName,
      prettyLabel: MatrixColumnLabels.ChildRequirementName,
    },
    {
      label: MatrixColumnId.ParentShallStatement,
      prettyLabel: MatrixColumnLabels.ParentShallStatement,
    },
    {
      label: MatrixColumnId.ChildShallStatement,
      prettyLabel: MatrixColumnLabels.ChildShallStatement,
    },
  ],
}

const FILTER_GROUP_2 = {
  spaceBetween: true,
  filters: [
    {
      label: MatrixColumnId.Validation,
      prettyLabel: MatrixColumnLabels.Validation,
    },
    {
      label: MatrixColumnId.Verification,
      prettyLabel: MatrixColumnLabels.Verification,
    },
    {
      label: MatrixColumnId.ValidationRecordTitle,
      prettyLabel: MatrixColumnPrettyLabels.RecordTitle,
    },
    {
      label: MatrixColumnId.VerificationRecordTitle,
      prettyLabel: MatrixColumnPrettyLabels.RecordTitle,
    },
    {
      label: MatrixColumnId.ValidationDescriptionOfActivity,
      prettyLabel: MatrixColumnPrettyLabels.ValidationDescriptionOfActivity,
    },
    {
      label: MatrixColumnId.VerificationDescriptionOfActivity,
      prettyLabel: MatrixColumnPrettyLabels.VerificationDescriptionOfActivity,
    },
  ],
}

const Matrix = () => {
  const { requirementId: urlRequirementId } = useParams()
  const [queryParameters] = useSearchParams()
  const scrollToRequirementId =
    queryParameters.get('requirementId') || urlRequirementId

  const {
    specification,
    matrixViewUIConfig,
    revision,
    updateMatrixViewUIConfig,
    createRequirement,
    isHistoricalRevision,
    publicTenant,
  } = useSpecificationContext()
  const [filters, toggleFilter] = useFilterState(FILTER_KEYS)
  const { generateSpecificationReport } = useReports()
  const { openShareSpecificationModal } = useModals()
  const { evidenceLinks, requirements, blockOrder, bodySectionId } =
    useMatrixContext()
  const { userIsEditor } = useAuth()

  const userCanEdit = userIsEditor(specification.id)
  const revisionId = revision.id

  const [expandAll, setExpandAll] = useState(false)

  const filteredRequirements = useMemo(() => {
    return requirements
      .filter((r) =>
        revision.status === RevisionStatus.ACTIVE
          ? r.status === RequirementStatus.Active
          : true,
      )
      .filter(
        (r) =>
          filters[FilterKey.RequirementStatus].length === 0 ||
          filters[FilterKey.RequirementStatus].includes(r.status),
      )
      .filter(
        (r) =>
          filters[FilterKey.RequirementType].length === 0 ||
          r.types.some((type) =>
            filters[FilterKey.RequirementType].includes(type),
          ),
      )
      .filter(
        (r) =>
          filters[FilterKey.Compliance].length === 0 ||
          filters[FilterKey.Compliance].includes(r.compliance),
      )
      .filter((r) => {
        if (filters[FilterKey.ValidationMethod].length === 0) {
          return true
        }

        const evidenceMethods = evidenceLinks
          .filter((link) => link.evidence.type === EvidenceType.Validation)
          .filter((link) => link.contextId === r.context.id)
          .map((link) => link.evidence.method)

        return filters[FilterKey.ValidationMethod].some((vm) =>
          evidenceMethods.includes(vm),
        )
      })
      .filter((r) => {
        if (filters[FilterKey.VerificationMethod].length === 0) {
          return true
        }

        const evidenceMethods = evidenceLinks
          .filter((link) => link.evidence.type === EvidenceType.Verification)
          .filter((link) => link.contextId === r.context.id)
          .map((link) => link.evidence.method)

        return filters[FilterKey.VerificationMethod].some((vm) =>
          evidenceMethods.includes(vm),
        )
      })
      .map((r) => ({
        ...r,
        publicTenant,
      }))
  }, [requirements, revision.status, filters, evidenceLinks, publicTenant])

  const filteredColumns = useMemo(
    () =>
      (publicTenant ? PublicSpecMatrixColumns : MatrixColumns)
        .filter(
          (col) =>
            !matrixViewUIConfig.some(
              (item) => item.columnId === col.label && item.hidden,
            ),
        )
        .filter(
          (col) =>
            !(specification.external && col.label === MatrixColumnId.Status),
        ),
    [matrixViewUIConfig, publicTenant, specification.external],
  )

  const columnResizer = useResizableColumns(filteredColumns, 'matrixTable')

  const filteredColumnIds = matrixViewUIConfig
    .filter((item) => item.hidden)
    .map((item) => item.columnId)

  const scrollToIndex = filteredRequirements.findIndex(
    (requirement) => requirement.id === scrollToRequirementId,
  )

  const filterGroups = [FILTER_GROUP_0, FILTER_GROUP_1, FILTER_GROUP_2].map(
    (group) => {
      return {
        ...group,
        filters: group.filters.filter((filter) => {
          if (
            specification.external &&
            filter.label === MatrixColumnId.Status
          ) {
            return false
          }
          return matrixViewUIConfig.some((configItem) => {
            return configItem.columnId === filter.label
          })
        }),
      }
    },
  ) as FilterGroup[]

  return (
    <div className={styles.root}>
      <div className={styles.actions}>
        <Button
          text={expandAll ? 'Collapse' : 'Expand'}
          onClick={() => setExpandAll(!expandAll)}
        />
        {!publicTenant && (
          <DisplayFilter
            filterGroups={filterGroups}
            selectedFilters={filteredColumnIds}
            onFilterClick={(filterName) =>
              updateMatrixViewUIConfig(filterName as MatrixColumnId)
            }
          />
        )}
        <FilterDropdown
          menus={FILTER_KEYS}
          activeFilters={filters}
          onSelectFilter={toggleFilter}
        />
        <AppliedFilters
          filterKeys={FILTER_KEYS}
          filters={filters}
          onRemove={toggleFilter}
        />
        <div>
          {!publicTenant && (
            <div className={styles.reportActions}>
              {userCanEdit && (
                <PostHogFeature flag="show-sharing-portal" match>
                  <Button
                    text="Share externally"
                    onClick={() =>
                      openShareSpecificationModal({
                        specification,
                      })
                    }
                  />
                </PostHogFeature>
              )}
              <Button
                text="Generate report"
                onClick={() =>
                  generateSpecificationReport(
                    specification.id,
                    revisionId,
                    specification.status,
                  )
                }
              />
              <Button
                text="Export Excel"
                onClick={() =>
                  requirementsApi.exportRequirements(specification.id)
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.inner}>
          <VirtualTable
            rowItems={filteredRequirements}
            columnResizer={columnResizer}
            scrollToIndex={scrollToIndex}
            expandAll={expandAll}
          />
          {revision.status === RevisionStatus.DRAFT &&
            userCanEdit &&
            !publicTenant &&
            !isHistoricalRevision && (
              <button
                className={styles.add}
                onClick={() =>
                  createRequirement(
                    bodySectionId,
                    blockOrder.slice(-1)[0] || null,
                    {},
                  )
                }
              >
                <Add />
              </button>
            )}
        </div>
      </div>
    </div>
  )
}

export default Matrix
