import { Add, CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './AttributeValueSelect.module.css'
import { AttributeValueResponse } from '../../api/v2/attributes.ts'
import useClickOutside from '../../hooks/useClickOutside.ts'
import Dropdown from '../dropdown'
import Tag from '../tag'

interface AttributeValueSelectProps {
  attributeValues: AttributeValueResponse[]
  activeAttributeValues: AttributeValueResponse[]
  className?: string
  onlyDisplayOnHover?: boolean
  readOnly?: boolean
  selectedValueId?: string
  onCancel?: (program: AttributeValueResponse | null) => void
  onSelect: (program: AttributeValueResponse | null) => void
}

const AttributeValueSelect = (props: AttributeValueSelectProps) => {
  const {
    attributeValues,
    activeAttributeValues,
    className,
    onlyDisplayOnHover,
    readOnly,
    onCancel,
    onSelect,
    selectedValueId,
  } = props
  const selectedValue = attributeValues.find((p) => p.id === selectedValueId)
  const [open, setOpen] = useState(false)
  const closeMenu = () => {
    setOpen(false)
  }
  const ref = useClickOutside(closeMenu)

  return (
    <div
      ref={ref}
      className={`${styles.attributeValue} ${
        onlyDisplayOnHover ? '' : styles.displayAddBtn
      }  ${className}`}
    >
      {selectedValue && (
        <Tag
          text={selectedValue.name}
          color={{
            fontColor: selectedValue.metadata.STYLES.COLOR_FONT,
            backgroundColor: selectedValue.metadata.STYLES.COLOR_BG,
          }}
          onCancel={
            readOnly || !onCancel ? undefined : () => onCancel(selectedValue)
          }
        />
      )}
      {!readOnly && activeAttributeValues.length > 0 && (
        <>
          <button
            className={selectedValue ? '' : styles.addBtn}
            onClick={() => setOpen(!open)}
          >
            {selectedValue ? <CaretDown size={20} /> : <Add />}
          </button>
          <Dropdown className={styles.dropdown} isOpen={open}>
            {activeAttributeValues
              .filter((attrValue) => attrValue.id !== selectedValue?.id)
              .map((attrValue) => {
                const { COLOR_FONT: fontColor, COLOR_BG: backgroundColor } =
                  attrValue.metadata.STYLES

                return (
                  <button
                    key={attrValue.id}
                    className={styles.option}
                    onClick={() => {
                      closeMenu()
                      onSelect(attrValue)
                    }}
                  >
                    <Tag
                      text={attrValue?.name}
                      color={{ fontColor, backgroundColor }}
                    />
                  </button>
                )
              })}
          </Dropdown>
        </>
      )}
    </div>
  )
}

export default AttributeValueSelect
