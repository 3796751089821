import { CaretDown } from '@carbon/icons-react'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './EvidenceRecordList.module.css'
import EvidencesTableColumns, {
  ERCL,
  EvidenceRecordColumnLabels,
} from './EvidencesTableColumns'
import Button from '../../components/button'
import Dropdown from '../../components/dropdown'
import FilterDropdown, {
  AppliedFilters,
  useFilterState,
} from '../../components/filter-menu'
import { FilterKey } from '../../components/filter-menu/types'
import LiveSearchInput from '../../components/input/LiveSearchInput.tsx'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator'
import Table from '../../components/table/Table'
import Tag, { TAG_COLORS } from '../../components/tag'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useEvidencesContext } from '../../context/EvidencesContext'
import useClickOutside from '../../hooks/useClickOutside.ts'
import useLoadSearchParams, {
  hasLoadedEvidenceProgramFilter,
  LoadSearchParamConfig,
} from '../../hooks/useLoadSearchParams.ts'
import { useModals } from '../../hooks/useModals'
import { LoadingState } from '../../types/enums'

const FILTER_KEYS = [
  FilterKey.EvidenceType,
  FilterKey.EvidenceMethod,
  FilterKey.SpecificationName,
  FilterKey.EvidenceCadence,
  FilterKey.EvidenceProgram,
]

interface RouteParams extends Record<string, string | undefined> {
  evidenceId?: string
}

const FilterDisplay = ({ columnsToShow, setColumnsToShow }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  const ref = useClickOutside(onClose, isOpen)

  return (
    <div className={styles.displayFilter} ref={ref}>
      <Button
        text="Display"
        endIcon={<CaretDown />}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      />
      <Dropdown className={styles.dropdown} isOpen={isOpen}>
        {EvidencesTableColumns.filter(({ label }) => label !== '').map(
          ({ label }) => {
            const show = columnsToShow[label]
            return (
              <button
                key={label}
                onClick={() =>
                  setColumnsToShow({ ...columnsToShow, [label]: !show })
                }
              >
                <Tag
                  className={styles.displayTag}
                  text={label}
                  color={show ? TAG_COLORS.primary : TAG_COLORS.secondary}
                  uppercase={false}
                />
              </button>
            )
          },
        )}
      </Dropdown>
    </div>
  )
}

const EvidenceRecordList = () => {
  const { evidences, evidenceRecordLoading } = useEvidencesContext()
  const { activePrograms } = useAttributesContext()
  const [filters, toggleFilter] = useFilterState(FILTER_KEYS)
  const { evidenceId: scrollToEvidenceId } = useParams<RouteParams>()
  const { openEvidenceDrawer } = useModals()
  const [query, setQuery] = useState('')
  const [columnsToShow, setColumnsToShow] = useState<
    Record<Exclude<ERCL, ''>, boolean>
  >({
    Record: true,
    Type: true,
    'Date Created': true,
    'Linked Requirements': true,
    'Linked Specifications': true,
    'Linked Activities': true,
    Method: true,
    Cadence: true,
    Program: true,
  })

  const loadSearchParamsConfig = useCallback(
    () =>
      [
        {
          key: 'programId',
          hasLoaded: (val) =>
            hasLoadedEvidenceProgramFilter(
              val,
              activePrograms,
              filters,
              toggleFilter,
            ),
        },
      ] as LoadSearchParamConfig[],
    [activePrograms, filters, toggleFilter],
  )
  useLoadSearchParams(loadSearchParamsConfig())

  const filteredEvidences = useMemo(() => {
    return evidences
      .filter((record) =>
        record.title.toLowerCase().includes(query.toLowerCase()),
      )
      .filter(
        (e) =>
          filters[FilterKey.EvidenceType].length === 0 ||
          filters[FilterKey.EvidenceType].includes(e.type),
      )
      .filter(
        (e) =>
          filters[FilterKey.EvidenceMethod].length === 0 ||
          filters[FilterKey.EvidenceMethod].some((f) => f === e.method),
      )
      .filter(
        (e) =>
          filters[FilterKey.SpecificationName].length === 0 ||
          e.links.some((link) =>
            filters[FilterKey.SpecificationName].some(
              (f) => f.id === link.data.specificationId,
            ),
          ),
      )
      .filter(
        (e) =>
          filters[FilterKey.EvidenceCadence].length === 0 ||
          e.cadences.some((cadence) =>
            filters[FilterKey.EvidenceCadence].includes(cadence),
          ),
      )
      .filter(
        (e) =>
          filters[FilterKey.EvidenceProgram].length === 0 ||
          filters[FilterKey.EvidenceProgram].includes(e.program),
      )
      .sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime())
  }, [evidences, filters, query])

  const scrollToIndex = filteredEvidences.findIndex(
    (evidence) => evidence.id === scrollToEvidenceId,
  )

  const filteredEvidencesTableColumns = EvidencesTableColumns.filter(
    (column) => columnsToShow[column.label],
  )

  return (
    <>
      <div className={styles.evidences}>
        <div className={styles.actions}>
          <LiveSearchInput
            className={styles.search}
            query={query}
            setQuery={(query) => setQuery(query)}
            placeholder="Search for records"
          />
          <FilterDisplay
            columnsToShow={columnsToShow}
            setColumnsToShow={setColumnsToShow}
          />
          <FilterDropdown
            menus={FILTER_KEYS}
            activeFilters={filters}
            onSelectFilter={toggleFilter}
          />
          <AppliedFilters
            filterKeys={FILTER_KEYS}
            filters={filters}
            onRemove={toggleFilter}
          />
        </div>
        {evidenceRecordLoading === LoadingState.Loading && (
          <div className={styles.center}>
            <LoadingIndicator />
          </div>
        )}
        {evidenceRecordLoading === LoadingState.Loaded &&
          (filteredEvidences.length > 0 ? (
            <div className={styles.table}>
              <div className={styles.inner}>
                <Table
                  rowItems={filteredEvidences}
                  columns={filteredEvidencesTableColumns}
                  scrollToIndex={scrollToIndex}
                  onRowClick={(rowData) => {
                    openEvidenceDrawer({
                      evidenceRecord: rowData,
                    })
                  }}
                  ignoredRowClickColumns={[
                    EvidenceRecordColumnLabels.ACTIONS,
                    EvidenceRecordColumnLabels.LINKED_REQUIREMENTS,
                    EvidenceRecordColumnLabels.LINKED_SPECIFICATIONS,
                    EvidenceRecordColumnLabels.LINKED_ACTIVITES,
                  ]}
                />
              </div>
            </div>
          ) : (
            <div className={styles.noResults}>
              No results matching current query
            </div>
          ))}
        {evidenceRecordLoading === LoadingState.Failed && (
          <div className={styles.noResults}>Unable to load results</div>
        )}
      </div>
    </>
  )
}

export default EvidenceRecordList
