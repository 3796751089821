import api from './api'
import { BlockType } from './blocks'

const createUrl = (linkTarget: LinkTarget, targetId?: number) =>
  `/api/v2/documents/blocks/links/${linkTarget}${
    targetId ? `/${targetId}` : ''
  }`

enum LinkTarget {
  Block = 'blocks',
  Entity = 'entities',
}

export enum LinkType {
  ParentChild = 'specifications://requirements/fromParentToChild',
  Evidence = 'specifications://requirements/evidenceRequirement',
  Exception = 'specifications://requirements/exceptionRequirement',
}

enum LinkSource {
  Entity = 'ENTITY',
  DocumentBlock = 'DOCUMENT_BLOCK',
}

export enum LinkEntityType {
  Asset = 'ASSET',
  Specification = 'SPECIFICATION',
  Revision = 'SPECIFICATION_REVISION',
  Requirement = 'REQUIREMENT',
  Evidence = 'EVIDENCE',
  Exception = 'EXCEPTION',
}

type BlockData = {
  documentId: string
  blockId: string
  contextId: string
}

export interface BlockLink {
  id: number
  link: string
  from: BlockData
  to: BlockData
  data: any
}

export interface EntityLink {
  link: LinkType
  source: LinkSource
  entityType: LinkEntityType
  /**  @format guid */
  entityId: string
  /**  @format guid */
  documentId: string
  /**  @format guid */
  documentBlockId: string
  /**  @format guid */
  documentBlockContextId: string
  id: number
  data: any
}

type LinkQuery = BlockLinkQuery | EntityLinkQuery

interface BlockLinkQuery {
  blockId?: string[]
  blockType?: BlockType
  direction?: 'FROM' | 'TO'
  documentId?: string[]
  id?: number[]
  link?: LinkType[]
  limit?: number
  token?: number
}

interface EntityLinkQuery {
  documentBlockId?: string[]
  documentBlockType?: BlockType[]
  documentId?: string[]
  entityId?: string[]
  entityType?: LinkEntityType[]
  id?: number[]
  link?: LinkType[]
  source?: LinkSource
  limit?: number
  token?: number
}

export const createParentChildLink: (
  parent: BlockData & { specificationId: string },
  child: BlockData & { specificationId: string },
) => Promise<{ id: number }> = (parent, child) => {
  const { specificationId: parentSpecificationId, ...parentData } = parent
  const { specificationId: childSpecificationId, ...childData } = child

  return api.post(createUrl(LinkTarget.Block), {
    body: {
      link: LinkType.ParentChild,
      from: parentData,
      to: childData,
      data: {
        parentSpecificationId,
        childSpecificationId,
      },
    },
  })
}

const createQueryString: (query: LinkQuery) => string = (query) =>
  '?'.concat(
    Object.keys(query)
      .map((key) =>
        Array.isArray(query[key])
          ? query[key].map((value) => `${key}=${value}`).join('&')
          : `${key}=${query[key]}`,
      )
      .join('&'),
  )

export const getBlockLinks: (
  query: BlockLinkQuery,
) => Promise<BlockLink[]> = async (query) => {
  const { links } = await api.get(
    `${createUrl(LinkTarget.Block)}${createQueryString(query)}`,
  )
  return links
}

export const deleteBlockLink: (linkId: number) => Promise<{ id: number }> = (
  linkId,
) => api.delete(createUrl(LinkTarget.Block, linkId))

export const deleteEntityLink: (linkId: number) => Promise<{ id: number }> = (
  linkId,
) => api.delete(createUrl(LinkTarget.Entity, linkId))

export const getEntityLinks: (
  query: EntityLinkQuery,
) => Promise<EntityLink[]> = async (queryProp) => {
  const query = {
    ...queryProp,
    limit: (queryProp?.limit || 0) > 0 ? queryProp.limit : 1000,
  }

  const { links } = await api.get(
    `${createUrl(LinkTarget.Entity)}${createQueryString(query)}`,
  )
  return links
}

export const createEvidenceLink: (
  documentId: string,
  requirementId: string,
  evidenceId: string,
  specificationId: string,
) => Promise<{ id: number }> = (
  documentId,
  requirementId,
  evidenceId,
  specificationId,
) =>
  api.post(createUrl(LinkTarget.Entity), {
    body: {
      link: LinkType.Evidence,
      source: LinkSource.DocumentBlock,
      entityType: LinkEntityType.Evidence,
      entityId: evidenceId,
      documentId,
      documentBlockId: requirementId,
      data: { specificationId },
    },
  })

export type CreateExceptionLinkRequest = {
  exceptionId: string
  specificationId: string
  documentId: string
  requirementId: string
}

export const createExceptionLink = ({
  exceptionId,
  specificationId,
  documentId,
  requirementId,
}: CreateExceptionLinkRequest): Promise<{
  id: number
}> =>
  api.post(createUrl(LinkTarget.Entity), {
    body: {
      link: LinkType.Exception,
      source: LinkSource.DocumentBlock,
      entityType: LinkEntityType.Exception,
      entityId: exceptionId,
      documentId,
      documentBlockId: requirementId,
      data: { specificationId },
    },
  })
