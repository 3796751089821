import {
  Add,
  AddFilled,
  Checkmark,
  CheckmarkFilled,
  Export,
  SendAlt,
  Settings,
  UserMultiple,
} from '@carbon/icons-react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './SpecificationActions.module.css'
import { RevisionStatus } from '../../api/v2/revisions'
import Button, { BUTTON_COLORS } from '../../components/button'
import { toastError, toastSuccess } from '../../components/toast'
import { useAuth } from '../../context/AuthContext'
import { useSpecificationContext } from '../../context/SpecificationContext'
import { useModals } from '../../hooks/useModals'
import {
  EntityReviewEventOperation,
  EntityReviewReviewerEventOperation,
} from '../../types/api/v2/entity'

interface SpecificationActionsProps {
  pendingApproval: boolean
  userIsReviewer: boolean
  userCanReleaseReview: boolean
  isExternal: boolean
  onExport: () => void
}

const SpecificationActions = (props: SpecificationActionsProps) => {
  const {
    pendingApproval,
    userIsReviewer,
    userCanReleaseReview,
    isExternal,
    onExport,
  } = props
  const enableSpecificationExporting = useFeatureFlagEnabled('spec-exporting')
  const { openSpecificationReviewersModal, openConfirmationModal } = useModals()
  const { userIsOwner } = useAuth()
  const navigate = useNavigate()
  const {
    createRevision,
    createReview,
    updateReview,
    submitReview,
    revision,
    specification,
    isHistoricalRevision,
    pendingReview,
  } = useSpecificationContext()

  const userCanRequestReview =
    userIsOwner(specification.id) &&
    revision &&
    [RevisionStatus.DRAFT, RevisionStatus.REVIEW].includes(revision.status)

  const userCanCreateNewVersion =
    userIsOwner(specification.id) &&
    revision &&
    [RevisionStatus.ACTIVE, RevisionStatus.ARCHIVED].includes(revision.status)

  const [submittingApproveRevision, setSubmittingApproveRevision] =
    useState<boolean>(false)
  const approveRevision = useCallback(async () => {
    setSubmittingApproveRevision(true)
    try {
      await submitReview(EntityReviewReviewerEventOperation.APPROVE)
      toastSuccess('Revision approved')
    } catch (error) {
      console.error('Unable to approve revision', error)
    } finally {
      setSubmittingApproveRevision(false)
    }
  }, [submitReview])

  const confirmApproveRevision = () => {
    openConfirmationModal({
      title: 'Approve Specification',
      titleIcon: <CheckmarkFilled size={20} />,
      promptText: (
        <div style={{ paddingBottom: '18px' }}>
          <div
            style={{
              color: 'var(--text-color-gray-dark)',
              paddingBottom: '4px',
            }}
          >
            Confirmation
          </div>
          <div style={{ color: 'var(--text-color-gray-dark)' }}>
            Are you sure you want to approve this specification?
          </div>
        </div>
      ),
      confirmText: 'Approve',
      onConfirm: approveRevision,
    })
  }

  const [submittingReleaseRevision, setSubmittingReleaseRevision] =
    useState<boolean>(false)
  const releaseRevision = useCallback(async () => {
    setSubmittingReleaseRevision(true)
    try {
      await updateReview(EntityReviewEventOperation.APPROVE)
      toastSuccess('Specification released')
    } catch (error) {
      console.error('Unable to release specification', error)
      toastError('Unable to release specification', '')
    } finally {
      setSubmittingReleaseRevision(false)
    }
  }, [updateReview])

  const confirmReleaseRevision = () => {
    openConfirmationModal({
      title: 'Release specification',
      titleIcon: <CheckmarkFilled size={20} />,
      promptText: (
        <div style={{ paddingBottom: '30px' }}>
          <div
            style={{
              color: 'var(--text-color-gray-dark)',
              paddingBottom: '4px',
            }}
          >
            Details
          </div>
          <div style={{ color: 'var(--text-color-gray-dark)' }}>
            All requested reviewers have approved this specification. Release
            this specification to make its status Active.
          </div>
        </div>
      ),
      confirmText: 'Release',
      onConfirm: releaseRevision,
      showCancelButton: false,
    })
  }

  const [submittingNewVersion, setSubmittingNewVersion] =
    useState<boolean>(false)

  const createNewVersion = useCallback(async () => {
    setSubmittingNewVersion(true)
    try {
      await createRevision()
      toastSuccess('New version created')
    } catch (error) {
      console.error('Unable to create new version', error)
      toastError('Unable to create new version', 'Reload page and try again')
    } finally {
      setSubmittingNewVersion(false)
    }
  }, [createRevision])

  const confirmNewVersion = useCallback(() => {
    openConfirmationModal({
      title: 'Create new version',
      titleIcon: <AddFilled size={20} />,
      promptText: (
        <div style={{ paddingBottom: '18px' }}>
          <div
            style={{
              color: 'var(--text-color-gray-dark)',
              paddingBottom: '4px',
            }}
          >
            Confirmation
          </div>
          <div style={{ color: 'var(--text-color-gray-dark)' }}>
            Are you sure you want to create a new version of this specification?
          </div>
        </div>
      ),
      confirmText: 'Confirm',
      confirmIcon: <Add size={20} />,
      onConfirm: createNewVersion,
    })
  }, [createNewVersion, openConfirmationModal])

  return (
    <div className={styles.actions}>
      {!isExternal && userCanReleaseReview && (
        <Button
          disabled={submittingReleaseRevision}
          text="Release"
          color={BUTTON_COLORS.PRIMARY}
          endIcon={<SendAlt />}
          onClick={confirmReleaseRevision}
        ></Button>
      )}
      {!isExternal && userIsReviewer && pendingApproval && (
        <Button
          disabled={!pendingApproval || submittingApproveRevision}
          onClick={confirmApproveRevision}
          text="Approve"
          color={BUTTON_COLORS.PRIMARY}
          endIcon={<Checkmark />}
        />
      )}
      {!isExternal &&
        !isHistoricalRevision &&
        userCanCreateNewVersion &&
        !pendingApproval && (
          <Button
            text="New Version"
            color={BUTTON_COLORS.PRIMARY}
            disabled={submittingNewVersion}
            onClick={confirmNewVersion}
            endIcon={<Add />}
          />
        )}
      {!isExternal && userCanRequestReview && (
        <Button
          text="Reviews"
          endIcon={<UserMultiple />}
          onClick={() =>
            openSpecificationReviewersModal({
              specification,
              revision,
              pendingReview,
              createReview,
              updateReview,
            })
          }
        />
      )}
      <Button
        text="Settings"
        onClick={() => navigate(`/specifications/${specification.id}/settings`)}
        endIcon={<Settings />}
      />
      {enableSpecificationExporting && (
        <Button
          text="Export"
          onClick={() => {
            onExport()
          }}
          endIcon={<Export />}
        />
      )}
    </div>
  )
}

export default SpecificationActions
