import { CaretDown, CaretUp } from '@carbon/icons-react'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { useEffect, useRef, useState } from 'react'
import { DRAWER_TAB } from './enums.ts'
import Drawer from './index.tsx'
import styles from './RequirementDrawer.module.css'
import { Requirement } from '../../api/v2/requirements.ts'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import {
  extractRationaleDelta,
  extractShallStatementDelta,
} from '../../lib/requirement.ts'
import { isEmptyDelta } from '../../lib/string.ts'
import RequirementComments from '../../pages/specification/requirement-comments/RequirementComments.tsx'
import Graph from '../../pages/specification/requirement-graph/Graph.tsx'
import { RequirementStatus } from '../../types/enums.ts'
import QuillContent from '../quill-content/QuillContent.tsx'
import RequirementHistoryEvents from '../requirement/RequirementHistoryEvents.tsx'
import Tag, { CustomColorSet } from '../tag/index.tsx'
import RequirementStatusTag from '../tag/RequirementStatusTag.tsx'

const HistoryTab = (props) => {
  const { requirement, specificationId } = props

  return (
    <div className={styles.tabContent}>
      <div className={styles.history}>
        <RequirementHistoryEvents
          requirementId={requirement.id}
          specificationId={specificationId}
        />
      </div>
    </div>
  )
}

const CommentsTab = (props) => {
  const { requirement, specificationId, revisionId } = props

  return (
    <div className={styles.tabContent}>
      {requirement?.id && (
        <div className={styles.comments}>
          <RequirementComments
            specificationId={specificationId}
            revisionId={revisionId}
            requirement={requirement}
          />
        </div>
      )}
    </div>
  )
}

const GraphTab = (props) => {
  const { requirement, specificationId, closeModal } = props
  return (
    <div className={styles.tabContent}>
      <ParentSize>
        {({ width, height }) => (
          <Graph
            width={width}
            height={height}
            requirementId={requirement.id}
            specificationId={specificationId}
            closeModal={closeModal}
          />
        )}
      </ParentSize>
    </div>
  )
}

const TABS = {
  [DRAWER_TAB.COMMENTS]: CommentsTab,
  [DRAWER_TAB.HISTORY]: HistoryTab,
  [DRAWER_TAB.GRAPH]: GraphTab,
}

const DrawerTabs = (props) => {
  const { initialTab, requirement, specificationId, revisionId, closeModal } =
    props
  const [selectedTab, setSelectedTab] = useState(initialTab)

  const Tab = TABS[selectedTab]

  return (
    <div className={styles.tabs}>
      <div className={styles.tabsHeader}>
        <button
          className={
            selectedTab === DRAWER_TAB.GRAPH
              ? styles.activeTab
              : styles.inactiveTab
          }
          onClick={() => setSelectedTab(DRAWER_TAB.GRAPH)}
        >
          Graph
        </button>
        <button
          className={
            selectedTab === DRAWER_TAB.COMMENTS
              ? styles.activeTab
              : styles.inactiveTab
          }
          onClick={() => setSelectedTab(DRAWER_TAB.COMMENTS)}
        >
          Comments
        </button>
        <button
          className={
            selectedTab === DRAWER_TAB.HISTORY
              ? styles.activeTab
              : styles.inactiveTab
          }
          onClick={() => setSelectedTab(DRAWER_TAB.HISTORY)}
        >
          History
        </button>
      </div>
      <Tab
        requirement={requirement}
        specificationId={specificationId}
        revisionId={revisionId}
        closeModal={closeModal}
      />
    </div>
  )
}

export interface RequirementDrawerProps {
  requirement: Requirement
  initialTab: DRAWER_TAB
  specificationId: string
}

const RequirementDrawer = (
  props: RequirementDrawerProps & { closeModal: () => void },
) => {
  const {
    closeModal,
    requirement,
    initialTab = DRAWER_TAB.COMMENTS,
    specificationId,
  } = props
  const { title, sectionNumber, status } = requirement
  const { getRequirementTypeById } = useAttributesContext()

  const [isExpanded, setIsExpanded] = useState(false)
  const [initialHeight, setInitialHeight] = useState(0)
  const [isOverflowing, setIsOverflowing] = useState(false)

  const statementsContainerRef = useRef<HTMLDivElement | null>(null)
  const rationaleStatementRef = useRef<HTMLDivElement | null>(null)
  const popoverRef = useClickOutside(() => {
    setIsExpanded(false)
  })

  const types = (requirement.types || []).map((typeGuid) => {
    const type = getRequirementTypeById(typeGuid)
    return {
      text: type?.name ?? '',
      color: {
        fontColor: type?.metadata.STYLES.COLOR_FONT,
        backgroundColor: type?.metadata.STYLES.COLOR_BG,
      },
    }
  })

  useEffect(() => {
    const statementsContainer = statementsContainerRef.current
    if (statementsContainer) {
      setInitialHeight(statementsContainer.clientHeight)
    }
  }, [])

  useEffect(() => {
    const rationaleStatement = rationaleStatementRef.current
    const maxHeight = 15
    if (rationaleStatement) {
      setIsOverflowing(rationaleStatement.scrollHeight > maxHeight)
    }
  }, [])

  return (
    <Drawer onClose={closeModal} size={'large'}>
      <div className={styles.container}>
        <span className={styles.title}>
          {sectionNumber} · {title || 'Untitled'}
        </span>
        <span className={styles.tags}>
          <RequirementStatusTag status={status as RequirementStatus} />
          {types.map((tag) => (
            <Tag
              key={tag.text}
              text={tag.text.toUpperCase()}
              color={tag.color as CustomColorSet}
            />
          ))}
        </span>
        <div
          className={styles.popoverPlaceholder}
          style={{ height: `${initialHeight}px` }}
        >
          <div
            ref={popoverRef}
            className={`${styles.popover} ${
              isExpanded ? `${styles.expanded} ${styles.basicElevation}` : ''
            }`}
          >
            <div className={styles.popoverContent}>
              <div ref={statementsContainerRef} className={styles.statements}>
                <div className={styles.shallStatement}>
                  <span className={styles.statementTitle}>Requirement: </span>
                  <QuillContent
                    style={{ width: '100%' }}
                    readOnly
                    placeholder="Untitled"
                    delta={extractShallStatementDelta(requirement)}
                  />
                </div>
                {(!isEmptyDelta(requirement?.data?.delta?.rationale) ||
                  requirement?.rationale) && (
                  <div className={styles.rationale}>
                    <span className={styles.statementTitle}>Rationale: </span>
                    <div
                      ref={
                        !isEmptyDelta(requirement?.data?.delta?.rationale) ||
                        requirement?.rationale
                          ? rationaleStatementRef
                          : null
                      }
                      className={`${styles.rationaleStatement} ${
                        isOverflowing && isExpanded ? '' : styles.collapsed
                      }`}
                    >
                      <QuillContent
                        readOnly
                        placeholder=""
                        delta={extractRationaleDelta(requirement)}
                      />
                    </div>
                  </div>
                )}
              </div>
              {!isExpanded && isOverflowing && (
                <button
                  className={styles.caret}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsExpanded(true)
                  }}
                >
                  <CaretDown />
                </button>
              )}
            </div>
            {isExpanded && (
              <button
                className={styles.caret}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsExpanded(false)
                }}
              >
                <CaretUp />
              </button>
            )}
          </div>
        </div>
        <DrawerTabs
          requirement={requirement}
          initialTab={initialTab}
          specificationId={specificationId}
          revisionId={requirement.revisionId}
          closeModal={closeModal}
        />
      </div>
    </Drawer>
  )
}

export default RequirementDrawer
