import styles from './SearchTableColumns.module.css'
import { AttributeValueResponse } from '../../api/v2/attributes.ts'
import { getEvidenceRecord } from '../../api/v2/evidence.ts'
import { SearchRequirementContent } from '../../api/v2/search.ts'
import Chip from '../../components/chip'
import { TableItem } from '../../components/table/Table.tsx'
import Tag from '../../components/tag'
import ComplianceTag from '../../components/tag/ComplianceTag.tsx'
import { useAttributesContext } from '../../context/AttributesContext'
import { useModals } from '../../hooks/useModals.ts'
import {
  AttributeName,
  EvidenceType,
  ReportColumnId,
} from '../../types/enums.ts'

const RequirementIdentifierCell = (props: {
  data: SearchRequirementContent
}) => {
  const { openSearchRequirementDrawer } = useModals()
  const { data } = props
  const requirementIdentifier =
    (data?.specification?.specificationIdentifier
      ? data.specification.specificationIdentifier
      : 'Document Number') +
    `-${data?.requirement?.rootRequirementIdentifier || ''}`

  return (
    <div>
      <button
        className={styles.requirementIdentifier}
        onClick={() => {
          const searchResult = data as SearchRequirementContent
          openSearchRequirementDrawer({
            searchResult,
          })
        }}
      >
        {requirementIdentifier}
      </button>
    </div>
  )
}

const RequirementTitleCell = (props: { data: SearchRequirementContent }) => {
  const { openSearchRequirementDrawer } = useModals()
  const { data } = props
  const requirementTitle = data?.requirement?.title || 'Untitled'

  return (
    <div>
      <button
        className={styles.requirementTitle}
        onClick={() => {
          const searchResult = data as SearchRequirementContent
          openSearchRequirementDrawer({
            searchResult,
          })
        }}
      >
        <span>{requirementTitle}</span>
      </button>
    </div>
  )
}

const ShallStatementCell = (props: { data: SearchRequirementContent }) => {
  const { openSearchRequirementDrawer } = useModals()
  const { data } = props
  const shallStatement = data?.requirement?.shallStatement || 'Shall Statement'

  return (
    <div>
      <button
        onClick={() => {
          const searchResult = data as SearchRequirementContent
          openSearchRequirementDrawer({
            searchResult,
          })
        }}
        className={styles.shallStatement}
      >
        <span>{shallStatement}</span>
        {/*TODO - Change to delta once backend supports*/}
        {/*{delta && (*/}
        {/*  <div>*/}
        {/*    <QuillContent*/}
        {/*      placeholder="Shall Statement"*/}
        {/*      readOnly*/}
        {/*      delta={delta}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
      </button>
    </div>
  )
}

const ComplianceCell = (props) => {
  const { data } = props
  const { getComplianceStyles } = useAttributesContext()

  const complianceName = data?.requirement?.compliance
  const compliance = getComplianceStyles(complianceName)

  return (
    <div>
      {compliance && (
        <ComplianceTag
          complianceName={compliance.name}
          color={compliance.styles}
        />
      )}
    </div>
  )
}

const RationaleCell = (props) => {
  const { data } = props
  const rationale = data?.requirement?.rationale

  return (
    <div>
      <div className={styles.rationale}>
        <span>{rationale}</span>
        {/*TODO - Change to delta once backend supports*/}
        {/*<div>*/}
        {/*  <QuillContent placeholder="Shall Statement" readOnly delta={delta} />*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

const RequirementTypeCell = (props: TableItem) => {
  const { data, isExpanded, onExpand } = props
  const { requirementTypes } = useAttributesContext()
  const types = requirementTypes.filter((serverType) =>
    data.requirement.types.some((reqTypeId) => reqTypeId === serverType.id),
  )

  const displayedTypes = types.filter((_type, i) => isExpanded || i === 0)

  return (
    <div className={styles.tagList}>
      {displayedTypes.length > 0 &&
        displayedTypes.map((type) => (
          <Tag
            text={type.name}
            color={{
              fontColor: type.metadata.STYLES.COLOR_FONT,
              backgroundColor: type.metadata.STYLES.COLOR_BG,
            }}
          />
        ))}
      {types?.length > 1 && (
        <button
          className={`${styles.overflowCount} ${
            isExpanded ? styles.expanded : ''
          }`}
          onClick={onExpand}
        >
          <span className={styles.overflowCount}>+{types.length - 1}</span>
        </button>
      )}
    </div>
  )
}

const SpecificationNameCell = (props) => {
  const { data } = props

  const specName = data?.specification?.name || 'Specification Name'

  return <div className={styles.specificationName}>{specName}</div>
}

const StatusCell = (props) => {
  const { data } = props
  const status = data?.requirement?.status || ''

  return (
    <div>
      <Chip variant="status" value={status} />
    </div>
  )
}

const ProgramCell = (props) => {
  const { data } = props
  const { allAttributes, getProgramById } = useAttributesContext()
  const program = getProgramById(
    data.specification.attributes?.[allAttributes.PROGRAM?.id]?.[0] || '',
  )

  return (
    <div>
      {program && (
        <Tag
          text={program.name}
          color={{
            fontColor: program.metadata.STYLES.COLOR_FONT,
            backgroundColor: program.metadata.STYLES.COLOR_BG,
          }}
        />
      )}
    </div>
  )
}

export const EvidenceCell = (props: {
  data: SearchRequirementContent
  evidenceType: EvidenceType
  isExpanded?: boolean
  onExpand?: () => void
}) => {
  const { data, evidenceType, isExpanded, onExpand } = props
  const { getEvidenceMethodById, getProgramById, getAttributeByName } =
    useAttributesContext()
  const { openLinkedEvidenceViewModal } = useModals()
  const evidenceMethodId = getAttributeByName(AttributeName.EvidenceMethod)?.id
  const programId = getAttributeByName(AttributeName.SpecificationProgram)?.id

  const evidences = data.evidences
    ?.filter((evidence) => evidence.type === evidenceType)
    .map((evidence) => {
      const methods: Array<AttributeValueResponse> = evidenceMethodId
        ? evidence.attributes[evidenceMethodId]
            .map((methodId: string) => getEvidenceMethodById(methodId))
            .filter((attribute: AttributeValueResponse) => attribute !== null)
        : []

      const programs: Array<AttributeValueResponse> =
        programId && evidence.attributes[programId]
          ? evidence.attributes[programId]
              .map((programId: string) => getProgramById(programId))
              .filter((attribute: AttributeValueResponse) => attribute !== null)
          : []

      // One method attribute should always be populated for an evidence, while program is optional and could be null
      return {
        evidence,
        method: methods[0],
        program: programs[0],
      }
    })

  const displayedEvidences = evidences.filter(
    (_evidence, i) => isExpanded || i === 0,
  )

  return (
    <div className={styles.tagList}>
      {displayedEvidences &&
        displayedEvidences.map((evidence) => (
          <Tag
            className={styles.evidenceMethodTag}
            key={`${evidence.evidence.id}-${evidence.method.id}`}
            text={evidence.method.name.toUpperCase()}
            color={{
              fontColor: evidence.method.metadata.STYLES.COLOR_FONT,
              backgroundColor: evidence.method.metadata.STYLES.COLOR_BG,
            }}
            onClick={async () => {
              const linkedEvidence = await getEvidenceRecord(
                evidence.evidence.id,
              )
              openLinkedEvidenceViewModal({
                linkedEvidence,
                method: evidence.method,
                program: evidence.program,
              })
            }}
          />
        ))}
      {evidences?.length > 1 && (
        <button
          className={`${styles.overflowCount} ${
            isExpanded ? styles.expanded : ''
          }`}
          onClick={onExpand}
        >
          <span className={styles.overflowCount}>+{evidences.length - 1}</span>
        </button>
      )}
    </div>
  )
}

export const EvidenceTypeValidationCell = (props: {
  data: SearchRequirementContent
  isExpanded?: boolean
  onExpand?: () => void
}) => {
  return EvidenceCell({ ...props, evidenceType: EvidenceType.Validation })
}

export const EvidenceTypeVerificationCell = (props: {
  data: SearchRequirementContent
  isExpanded?: boolean
  onExpand?: () => void
}) => {
  return EvidenceCell({
    ...props,
    evidenceType: EvidenceType.Verification,
  })
}

export const COLUMN_ID_TO_NAME: Record<ReportColumnId, string> = {
  [ReportColumnId.RequirementIdentifier]: 'ID',
  [ReportColumnId.RequirementSectionNumber]: 'Section Number',
  [ReportColumnId.RequirementName]: 'Requirement Name',
  [ReportColumnId.RequirementShallStatement]: 'Shall Statement',
  [ReportColumnId.RequirementCompliance]: 'Compliance',
  [ReportColumnId.RequirementComplianceNotes]: 'Compliance Notes',
  [ReportColumnId.RequirementStatus]: 'Status',
  [ReportColumnId.RequirementType]: 'Type',
  [ReportColumnId.SpecificationName]: 'Specification',
  [ReportColumnId.RequirementRationale]: 'Rationale',
  [ReportColumnId.SpecificationProgramName]: 'Program',
  [ReportColumnId.ParentRequirementName]: 'Parent Requirement',
  [ReportColumnId.ParentRequirementShallStatement]: 'Parent Shall Statement',
  [ReportColumnId.ChildRequirementName]: 'Child Requirement',
  [ReportColumnId.ChildRequirementShallStatement]: 'Child Shall Statement',
  [ReportColumnId.EvidenceTypeValidation]: 'Validation',
  [ReportColumnId.ValidationRecordTitle]: 'Validation Record Title',
  [ReportColumnId.ValidationDescriptionOfActivity]:
    'Validation Description of Activity',
  [ReportColumnId.EvidenceTypeVerification]: 'Verification',
  [ReportColumnId.VerificationRecordTitle]: 'Verification Record Title',
  [ReportColumnId.VerificationDescriptionOfActivity]:
    'Verification Description of Activity',
}

const SearchTableColumns = [
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementIdentifier],
    width: 'minmax(max-content, 1fr)',
    Component: RequirementIdentifierCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementName],
    width: 'minmax(200px, 2fr)',
    Component: RequirementTitleCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementShallStatement],
    width: 'minmax(300px, 3fr)',
    Component: ShallStatementCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementCompliance],
    Component: ComplianceCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementRationale],
    width: 'minmax(300px, 3fr)',
    Component: RationaleCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementType],
    Component: RequirementTypeCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.SpecificationName],
    Component: SpecificationNameCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementStatus],
    Component: StatusCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.SpecificationProgramName],
    Component: ProgramCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.EvidenceTypeValidation],
    Component: EvidenceTypeValidationCell,
  },
  {
    label: COLUMN_ID_TO_NAME[ReportColumnId.EvidenceTypeVerification],
    Component: EvidenceTypeVerificationCell,
  },
]

export default SearchTableColumns
