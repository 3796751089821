import Tag from '.'
import { getCssVar } from '../../lib/string.ts'
import { RequirementStatus } from '../../types/enums'

const TAG_COLORS = {
  [RequirementStatus.Draft]: {
    backgroundColor: getCssVar('--color-draft-bg'),
    fontColor: getCssVar('--color-draft-text'),
  },
  [RequirementStatus.Review]: {
    backgroundColor: getCssVar('--color-review-bg'),
    fontColor: getCssVar('--color-review-text'),
  },
  [RequirementStatus.Active]: {
    backgroundColor: getCssVar('--color-active-bg'),
    fontColor: getCssVar('--color-active-text'),
  },
  [RequirementStatus.Archived]: {
    backgroundColor: getCssVar('--color-archived-bg'),
    fontColor: getCssVar('--color-archived-text'),
  },
}

const TEXT = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  REVIEW: 'IN REVIEW',
}

const RequirementStatusTag = (props: {
  status: RequirementStatus
  onCancel?: () => void
}) => {
  const { status, onCancel } = props
  return (
    <Tag text={TEXT[status]} color={TAG_COLORS[status]} onCancel={onCancel} />
  )
}

export default RequirementStatusTag
