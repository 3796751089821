import api from './api'
import { FilterKey, FilterSet } from '../../components/filter-menu/types'
import { ReportColumnId } from '../../types/enums'

const createUrl: () => string = () => '/api/v2/search'

export enum SearchResultStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  REVIEW = 'REVIEW',
  ARCHIVED = 'ARCHIVED',
}

export interface CreateReportRequest {
  name: string
  query: {
    query: string
    types?: string[]
    specificationPrograms?: string[]
    statuses?: string[]
    compliances?: string[]
    evidenceValidationMethods?: string[]
    evidenceVerificationMethods?: string[]
    token?: string
  }
  metadata: {
    columnsToDisplay: Array<ReportColumnId>
  }
}

export interface CreateReportResponse {
  reportId: string
}

export const createSearchReport: (
  req: CreateReportRequest,
) => Promise<CreateReportResponse> = (req) =>
  api.post(`${createUrl()}/requirements/reports`, {
    body: req,
  })

export interface SearchRequirementParams {
  query: string
  limit?: number
  token?: string | null
  filters: FilterSet
}

export type SearchRequirementContent = {
  specification: {
    id: string
    name: string
    specificationIdentifier: string
    attributes: Record<string, any>
  }
  revision: {
    id: string
    version: string
  }
  requirement: {
    id: string
    title: string
    shallStatement: string
    status: string
    rationale: string
    contextId: string
    compliance: string
    rootRequirementIdentifier: number
    types: Array<string>
    attributes: Record<string, any>
    createdBy: string
    createdByFullName: string
    createdOn: string
  }
  evidences: Array<{
    id: string
    type: string
    attributes: Record<string, any>
  }>
}

export type GetSearchRequirementsResponse = {
  contents: Array<SearchRequirementContent>
  requestToken: string
  nextToken: string
}

const QUERY_KEY = {
  [FilterKey.RequirementStatus]: 'status',
  [FilterKey.RequirementType]: 'types',
  [FilterKey.SpecificationProgram]: 'specificationProgram',
  [FilterKey.Compliance]: 'compliance',
  [FilterKey.ValidationMethod]: 'evidenceValidationMethod',
  [FilterKey.VerificationMethod]: 'evidenceVerificationMethod',
}

export const getSearchRequirements: (
  params: SearchRequirementParams,
) => Promise<GetSearchRequirementsResponse> = async ({
  query,
  limit,
  token,
  filters,
}) => {
  const searchParams = new URLSearchParams()

  if (query) {
    searchParams.append('query', query)
  }
  if (limit) {
    searchParams.append('limit', `${limit}`)
  }
  if (token) {
    searchParams.append('token', token)
  }

  Object.entries(filters).forEach(([param, filterList]) => {
    if (filterList) {
      filterList.forEach((value) =>
        searchParams.append(QUERY_KEY[param], value),
      )
    }
  })

  return api.get(`${createUrl()}/requirements?${searchParams.toString()}`)
}
