import { useCallback, useState } from 'react'
import { createDocumentSection } from '../../api/v2/documents.ts'
import * as evidenceApi from '../../api/v2/evidence.ts'
import { Evidence } from '../../api/v2/evidence.ts'
import { createEvidenceLink } from '../../api/v2/links.ts'
import { createRequirement } from '../../api/v2/requirements.ts'
import { getRevisions } from '../../api/v2/revisions.ts'
import { createSpecification } from '../../api/v2/specifications.ts'
import * as specApi from '../../api/v2/specifications.ts'
import Button from '../../components/button'
import { toastError, toastInfo, toastSuccess } from '../../components/toast'
import {
  AttributesContextProvider,
  useAttributesContext,
} from '../../context/AttributesContext.tsx'
import useDevTools from '../../context/DevToolsContext.tsx'
import { EvidenceType } from '../../types/enums.ts'

const DevCreateLargeSpec = () => {
  const defaultReqCount = 10
  const defaultEvidenceCount = 10
  const defaultLinkMax = defaultReqCount * defaultEvidenceCount

  const { useDevApi } = useDevTools()
  const { evidenceMethods } = useAttributesContext()

  const [requirementCount, setRequirementCount] =
    useState<number>(defaultReqCount)
  const [evidenceCount, setEvidenceCount] =
    useState<number>(defaultEvidenceCount)
  const [linkMax, setLinkMax] = useState<number>(defaultLinkMax)

  const createLargeSpec = useCallback(async () => {
    try {
      toastInfo(<span>Creating spec...</span>)
      const d = new Date()
      const time = `${
        d.getDate() +
        '/' +
        (d.getMonth() + 1) +
        '/' +
        d.getFullYear() +
        ' - ' +
        d.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        })
      }`

      const specificationIdentifier = `DEV -  ${time}`

      const spec = await createSpecification('Dev Tools - Large Spec')

      await specApi.updateSpecification(spec.id, {
        specificationIdentifier,
      })

      const revision = (await getRevisions(spec.id))[0]
      const documentSection = await createDocumentSection(
        spec.id,
        revision.documentId,
        null,
      )
      const reqIds = [] as string[]
      const evidences = [] as Evidence[]

      toastInfo(<span>Finished spec. Starting reqs...</span>)
      for (let i = 0; i < requirementCount; i++) {
        const rationale = `Req-${i} Rationale`
        const shallStatement = `Req-${i} Shall Statement`

        const req = await createRequirement(spec.id, documentSection.id, {
          title: `Req-${i} - ${time}`,
          shallStatement,
          rationale,
          sectionNumber: `Req-${i} Section Num`,
          data: {
            delta: {
              rationale: `{"ops":[{"insert":"${rationale}"}]}`,
              shallStatement: `{"ops":[{"insert":"${shallStatement}\\n"}]}`,
            },
          },
        })

        reqIds.push(req.id)
      }
      toastInfo(<span>Finished reqs. Starting records...</span>)

      for (let i = 0; i < evidenceCount; i++) {
        const newEvidence = await evidenceApi.createEvidenceRecord({
          method: evidenceMethods[0].id,
          program: '',
          title: `Evidence ${i} - ${time}`,
          cadences: [],
          description: '',
          complianceStatement: '',
          type: EvidenceType.Validation,
        })
        evidences.push(newEvidence)
      }

      toastInfo(<span>Finished records. Starting links...</span>)

      let linkCreationCount = 0

      for (let j = 0; j < evidences.length; j++) {
        if (linkCreationCount >= linkMax) {
          break
        }
        for (let i = 0; i < reqIds.length; i++) {
          if (linkCreationCount >= linkMax) {
            break
          }

          await createEvidenceLink(
            revision.documentId,
            reqIds[i],
            evidences[j].id,
            spec.id,
          )

          linkCreationCount = linkCreationCount + 1
        }
      }

      toastSuccess(
        <span>
          Finish creating large spec with {linkCreationCount} links!{' '}
          <a
            href={`/specifications/${spec.id}/matrix`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click me to go to matrix view
          </a>
        </span>,
      )
    } catch (e) {
      toastError('Failed to make large spec. Check console.', '')
      console.error('Failed to make large spec.', e)
    }
  }, [evidenceCount, evidenceMethods, linkMax, requirementCount])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ fontSize: '16px' }}>
        Generate Large Spec W/ Many Evidence Links
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="number"
          id="quantity"
          name="reqCount"
          min="1"
          step="100"
          defaultValue={defaultReqCount}
          value={requirementCount}
          onChange={(e) => {
            setRequirementCount(Number(e.target.value))
          }}
        />
        <label htmlFor="reqCount">Requirement Count</label>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="number"
          id="quantity"
          name="evidenceCount"
          min="0"
          step="100"
          defaultValue={defaultEvidenceCount}
          value={evidenceCount}
          onChange={(e) => {
            setEvidenceCount(Number(e.target.value))
          }}
        />
        <label htmlFor="evidenceCount">Evidence Count</label>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          style={{
            width: '100%',
          }}
          type="number"
          id="quantity"
          name="linkMax"
          min="0"
          step="100"
          defaultValue={defaultLinkMax}
          value={linkMax}
          onChange={(e) => {
            setLinkMax(Number(e.target.value))
          }}
        />
        <label htmlFor="evidenceCount">Evidence Link Max</label>
      </div>
      <Button
        text={`Create large spec ${useDevApi ? 'Disabled on dev' : ''}`}
        disabled={useDevApi}
        onClick={() => {
          createLargeSpec()
        }}
      />
      <div>
        (Link, req, or evidence creation over 1000 can take several mins. You
        can leave this tab open and inspect the specification.)
      </div>
    </div>
  )
}

export default () => (
  <AttributesContextProvider>
    <DevCreateLargeSpec />
  </AttributesContextProvider>
)
