import { usePostHog } from 'posthog-js/react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as teamsApi from '../api/v2/teams'
import { getTenant, TenantTier } from '../api/v2/tenants.ts'
import * as tokensApi from '../api/v2/tokens'
import * as usersApi from '../api/v2/users'
import { SpecificationPermission } from '../api/v2/users'
import { isAdminCheck } from '../lib/auth'
import { decodeJwtClaims, isValidJwtToken } from '../lib/jwt.ts'
import {
  getAuthToken,
  getRefreshToken,
  getRefreshTokenExpiryTime,
  KEY_AUTH_TOKEN,
  KEY_REFRESH_TOKEN,
} from '../lib/localstorage.ts'
import {
  EntityRole,
  LoadingState,
  PermissionEntityType,
} from '../types/enums.ts'

const REFRESH_TOKEN_INTERVAL = 15 * 60 * 1000

const checkIfSignedIn = () => {
  const token = getAuthToken()
  return isValidJwtToken(token)
}

const setAuthTokens = (authToken: string, refreshToken: string) => {
  // Manually fire local storage events
  // localstorage event handlers only listen to other tabs but we want them to respond to local storage changes on same window too
  const authTokenEvent = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key: KEY_AUTH_TOKEN,
    oldValue: getAuthToken(),
    newValue: authToken,
    url: window.location.href,
  })

  const refreshTokenEvent = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key: KEY_REFRESH_TOKEN,
    oldValue: getRefreshToken(),
    newValue: refreshToken,
    url: window.location.href,
  })

  localStorage.setItem(KEY_AUTH_TOKEN, authToken)
  localStorage.setItem(KEY_REFRESH_TOKEN, refreshToken)
  window.dispatchEvent(authTokenEvent)
  window.dispatchEvent(refreshTokenEvent)
}

const clearAuthToken = () => {
  // Manually fire local storage events
  // localstorage event handlers only listen to other tabs but we want them to respond to local storage changes on same window too
  const authTokenEvent = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key: KEY_AUTH_TOKEN,
    oldValue: getAuthToken(),
    newValue: undefined,
    url: window.location.href,
  })

  const refreshTokenEvent = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key: KEY_REFRESH_TOKEN,
    oldValue: getRefreshToken(),
    newValue: undefined,
    url: window.location.href,
  })

  localStorage.removeItem(KEY_AUTH_TOKEN)
  localStorage.removeItem(KEY_REFRESH_TOKEN)
  window.dispatchEvent(authTokenEvent)
  window.dispatchEvent(refreshTokenEvent)
}

export type AuthContext = {
  userDetails?: usersApi.User
  userPermissions?: usersApi.UserPermissions
  updateUserPermissions: (
    entityType: PermissionEntityType,
    entityId: string,
    role: EntityRole,
  ) => void
  userTeams?: teamsApi.Team[]
  signOut: (redirect?: boolean, redirectUrl?: string) => void
  isAdmin: boolean
  isSignedIn: boolean
  login: (
    email: string,
    password: string,
    headers?: Record<string, string>,
  ) => Promise<{ userId?: string } | { token?: string; refreshToken?: string }>
  verifyMfa: (
    userId: string,
    mfaCode: string,
    redirectUrl?: string,
  ) => Promise<void>
  resendMfa: (userId: string) => Promise<void>
  reload: () => void
  userIsOwner: (specId: string) => boolean
  userIsEditor: (specId: string) => boolean
  userIsCommenter: (specId: string) => boolean
  userTenantId: string
  userTenantLoading: LoadingState
  userTenant: { tier: TenantTier; name: string } | undefined
  setAuthTokens: (authToken: string, refreshToken: string) => void
}

const AuthCtx = createContext<AuthContext>({
  updateUserPermissions: () => {},
  isAdmin: false,
  isSignedIn: checkIfSignedIn(),
  login: () => Promise.resolve({ userId: undefined }),
  verifyMfa: () => Promise.resolve(),
  resendMfa: () => Promise.resolve(),
  signOut: () => {},
  reload: () => {},
  userIsOwner: () => false,
  userIsEditor: () => false,
  userIsCommenter: () => false,
  userTenantId: '',
  userTenantLoading: LoadingState.Loading,
  userTenant: undefined,
  setAuthTokens: () => {},
})

const AuthContextProvider = (props) => {
  const [userDetails, setUserDetails] = useState<usersApi.User>()
  const [userPermissions, setUserPermissions] =
    useState<usersApi.UserPermissions>()
  const [userTeams, setUserTeams] = useState<teamsApi.Team[]>()
  const [isSignedIn, setIsSignedIn] = useState<boolean>(checkIfSignedIn())
  const [userTenantId, setUserTenantId] = useState('')
  const [userTenantLoading, setUserTenantLoading] = useState<LoadingState>(
    LoadingState.Loading,
  )
  const [userTenant, setUserTenant] = useState<{
    tier: TenantTier
    name: string
  }>()

  const navigate = useNavigate()
  const location = useLocation()
  const posthog = usePostHog()

  const login = useCallback(
    async (
      email: string,
      password: string,
      headers?: Record<string, string>,
    ): Promise<tokensApi.LoginResponse | tokensApi.VerifyMfaResponse> => {
      const response = await tokensApi.login({ email, password }, headers)
      return response
    },
    [],
  )

  const verifyMfa = useCallback(
    async (
      userId: string,
      mfaCode: string,
      redirectUrl?: string,
    ): Promise<void> => {
      const tokens = await tokensApi.verifyMfa({ userId, mfaCode })
      if (tokens.token && tokens.refreshToken) {
        setAuthTokens(tokens.token, tokens.refreshToken)
        navigate(redirectUrl || '/')
      }
    },
    [navigate],
  )

  const resendMfa = useCallback(async (userId: string): Promise<void> => {
    await tokensApi.resendMfaCode({ userId })
  }, [])

  const signOut = useCallback(
    (redirect = true, redirectUrl?: string) => {
      clearAuthToken()
      posthog?.reset()
      if (redirect) {
        navigate(redirectUrl || '/login', {
          replace: true,
          state: { returnUrl: location.pathname },
        })
      }
    },
    [location.pathname, navigate, posthog],
  )

  const refreshAuthToken = useCallback(async (): Promise<void> => {
    if (!isSignedIn) {
      return
    }

    const refreshToken = getRefreshToken()
    const refreshTokenExpiryTime = getRefreshTokenExpiryTime()

    if (!refreshToken) {
      return
    }

    // Refresh token is stale, user needs to login manually
    if (
      !refreshTokenExpiryTime ||
      new Date(refreshTokenExpiryTime) < new Date()
    ) {
      signOut(true, location.pathname)
      return
    }

    try {
      const res = await tokensApi.refreshToken(refreshToken)
      const newAuthToken = JSON.stringify(res.accessToken || null)
      const newRefreshToken = JSON.stringify(res.refreshToken || null)
      setAuthTokens(newAuthToken, newRefreshToken)
    } catch (e) {
      signOut(true, location.pathname)
      return
    }
  }, [isSignedIn, location.pathname, signOut])

  const reload = useCallback(async () => {
    if (!isSignedIn) {
      return
    }

    const getCurrentUserId: () => string | null = () => {
      const jwtToken = getAuthToken()
      const { sub } = (jwtToken && decodeJwtClaims(jwtToken)) || { sub: null }

      return sub
    }

    const fetchUserDetails = async () => {
      const userId = getCurrentUserId()
      if (userId) {
        const user = await usersApi.getUser(userId)
        setUserDetails(user)
      }
    }

    const fetchUserPermissions = async () => {
      const res = await usersApi.getUserPermissions()
      setUserPermissions(res)
    }

    const fetchUserTeams = async () => {
      setUserTeams((await teamsApi.getCurrentUserTeams())?.teams || [])
    }

    const fetchUserTenant = async () => {
      setUserTenantLoading(LoadingState.Loading)
      const jwtToken = getAuthToken()
      const { tenantId } = (jwtToken && decodeJwtClaims(jwtToken)) || {
        tenantId: null,
      }

      if (tenantId) {
        setUserTenantId(tenantId)
        try {
          const tenant = await getTenant(tenantId)
          setUserTenant({ tier: tenant.tier, name: tenant.name })
          setUserTenantLoading(LoadingState.Loaded)
        } catch (error) {
          setUserTenantLoading(LoadingState.Failed)
          console.error('Unable to get tenant', error)
        }
      }
    }

    await Promise.all([
      fetchUserDetails(),
      fetchUserPermissions(),
      fetchUserTeams(),
      fetchUserTenant(),
    ])
  }, [isSignedIn])

  const updateUserPermissions = useCallback(
    async (
      entityType: PermissionEntityType,
      entityId: string,
      role: EntityRole,
    ) => {
      setUserPermissions((prev) => {
        if (!prev) {
          return prev
        }
        return {
          ...prev,
          [entityType]: {
            ...prev[entityType],
            [entityId]: {
              role,
            },
          },
        }
      })
    },
    [],
  )

  const userIsOwner = useCallback(
    (specId: string) =>
      isAdminCheck({ teams: userTeams }) ||
      userPermissions?.specifications?.[specId]?.role ===
        SpecificationPermission.Owner,
    [userPermissions?.specifications, userTeams],
  )

  const userIsEditor = useCallback(
    (specId: string) =>
      userIsOwner(specId) ||
      userPermissions?.specifications?.[specId]?.role ===
        SpecificationPermission.Editor,
    [userIsOwner, userPermissions?.specifications],
  )

  const userIsCommenter = useCallback(
    (specId: string) =>
      userIsEditor(specId) ||
      userPermissions?.specifications?.[specId]?.role ===
        SpecificationPermission.Commenter,
    [userIsEditor, userPermissions?.specifications],
  )

  // Let isSignedIn state be managed entirely by local storage listener.
  useEffect(() => {
    const storageEventHandler = () => {
      const newIsSignedIn = checkIfSignedIn()
      setIsSignedIn(newIsSignedIn)
    }
    window.addEventListener('storage', storageEventHandler)
    return () => {
      window.removeEventListener('storage', storageEventHandler)
    }
  }, [])

  useEffect(() => {
    reload()
  }, [reload])

  // Periodically refresh tokens and check if user needs to be logged in
  useEffect(() => {
    const interval = setInterval(async () => {
      await refreshAuthToken()
    }, REFRESH_TOKEN_INTERVAL)

    return () => clearInterval(interval)
  }, [refreshAuthToken])

  const isAdmin = isAdminCheck({ teams: userTeams })

  return (
    <AuthCtx.Provider
      value={{
        isSignedIn,
        isAdmin,
        userDetails,
        userPermissions,
        updateUserPermissions,
        userTeams,
        signOut,
        login,
        verifyMfa,
        resendMfa,
        reload,
        userIsOwner,
        userIsEditor,
        userIsCommenter,
        userTenantId,
        userTenantLoading,
        userTenant,
        setAuthTokens,
      }}
    >
      {props.children}
    </AuthCtx.Provider>
  )
}

export const useAuth: () => AuthContext = () => {
  return useContext(AuthCtx)
}

export { AuthContextProvider }
