import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styles from './RequirementDetailEvidence.module.css'
import { EntityLink } from '../../../api/v2/links'
import { Requirement } from '../../../api/v2/requirements'
import Tag, { TAG_COLORS } from '../../../components/tag'
import EvidenceActivityStatusTag from '../../../components/tag/EvidenceActivityStatusTag'
import EvidenceActivityTypeTag from '../../../components/tag/EvidenceActivityTypeTag'
import EvidenceTypeTag from '../../../components/tag/EvidenceTypeTag'
import { useAttributesContext } from '../../../context/AttributesContext'
import { useEvidenceActivitiesContext } from '../../../context/EvidenceActivitiesContext'
import {
  useEvidencesContext,
  EvidenceWithLinks,
} from '../../../context/EvidencesContext'
import useUsers from '../../../hooks/useUsers'

const RequirementDetailEvidence = () => {
  const { requirementId } = useParams()
  // Consider moving user fetching in parent component to avoid multiple api calls switching betweent outlets
  const { getUserById } = useUsers()
  const { evidences } = useEvidencesContext()
  const { evidenceActivities } = useEvidenceActivitiesContext()
  const { getEvidenceMethodById } = useAttributesContext()

  const filterEvidences = useCallback(
    (evidence: EvidenceWithLinks) => {
      return (
        evidence.links &&
        evidence.links.length > 0 &&
        evidence.links.some(
          (link: EntityLink & { requirement?: Requirement }) => {
            return link.requirement && link.requirement.id === requirementId
          },
        )
      )
    },
    [requirementId],
  )

  const requirementLinkedEvidenceRecords = useMemo(() => {
    return evidences.filter(filterEvidences)
  }, [evidences, filterEvidences])

  const getOwnerNamesString = useCallback(
    (owners: string[]) => {
      const ownerNames = owners.map((ownerId) => {
        const user = getUserById(ownerId)
        return user
          ? `${user.firstName} ${user.lastName}`.trim()
          : 'Unknown User'
      })

      if (ownerNames.length === 0) {
        return 'No Owners'
      } else if (ownerNames.length === 1) {
        return `Owner: ${ownerNames[0]}`
      } else {
        return `Owners: ${ownerNames.join(', ')}`
      }
    },
    [getUserById],
  )

  if (requirementLinkedEvidenceRecords.length === 0) {
    return <h3>No linked evidence available for this requirement.</h3>
  }

  return (
    <div>
      {requirementLinkedEvidenceRecords.map((evidence) => {
        const activities = evidenceActivities.filter(
          (activity) => activity.recordId === evidence.id,
        )
        const method = getEvidenceMethodById(evidence.method)
        const methodTagColor = method
          ? {
              fontColor: method.metadata.STYLES.COLOR_FONT,
              backgroundColor: method.metadata.STYLES.COLOR_BG,
            }
          : TAG_COLORS.gray3NoHover
        return (
          <div key={evidence.id} className={styles.evidenceRecordContainer}>
            <div className={styles.header}>
              <div className={styles.title}>{evidence.title}</div>
              <EvidenceTypeTag type={evidence.type} />
              <Tag
                aria-label={method ? method.name : ''}
                text={method ? method.name.toUpperCase() : ''}
                color={methodTagColor}
              />
            </div>
            <div className={styles.body}>
              <span className={styles.lineDescription}>
                Description of Activity:
              </span>{' '}
              {evidence.description}
            </div>
            <div className={styles.body}>
              <span className={styles.lineDescription}>
                Compliance Statement:
              </span>{' '}
              {evidence.complianceStatement}
            </div>

            <div className={styles.activitiesContainer}>
              <div className={styles.count}>
                {activities.length > 0
                  ? `${activities.length} Activities`
                  : 'No Activities linked with Record'}
              </div>
              {activities.map((activity) => (
                <div key={activity.id} className={styles.item}>
                  <div className={styles.header}>
                    <div className={styles.content}>
                      <div className={styles.title}>{activity.title}</div>
                      <EvidenceActivityTypeTag type={activity.type} />
                    </div>
                    <div className={styles.content}>
                      <EvidenceActivityStatusTag status={activity.status} />
                      <div className={styles.owners}>
                        {getOwnerNamesString(activity.owners)}
                      </div>
                    </div>
                  </div>
                  <div className={styles.memo}>{activity.memo}</div>
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default RequirementDetailEvidence
