import {
  CheckmarkFilled,
  NextOutline,
  ShareKnowledge,
} from '@carbon/icons-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from '.'
import styles from './ShareSpecificationModal.module.css'
import * as attributesApi from '../../api/v2/attributes.ts'
import { AttributeValueResponse } from '../../api/v2/attributes.ts'
import * as api from '../../api/v2/sharedSpecifications.ts'
import { ShareSnapshotResponse } from '../../api/v2/sharedSpecifications.ts'
import { Specification } from '../../api/v2/specifications.ts'
import { getTenantContacts, Tenant } from '../../api/v2/tenants'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import useClickOutside from '../../hooks/useClickOutside'
import {
  EntityRole,
  LoadingState,
  PermissionEntityType,
} from '../../types/enums.ts'
import Button, { BUTTON_COLORS } from '../button/index.tsx'
import Checkbox from '../input/Checkbox.tsx'
import { getRandomTagColor } from '../tag'
import { toastError } from '../toast'

export interface ShareSpecificationModalProps {
  specification: Specification
}

enum Step {
  SelectConnection,
  SelectProject,
  Success,
}

const ShareSpecificationModal = (
  props: ShareSpecificationModalProps & { closeModal: () => void },
) => {
  const { specification, closeModal } = props
  const { userTenantId, updateUserPermissions } = useAuth()
  const ref = useClickOutside(() => closeModal())
  const [step, setStep] = useState<Step>(Step.SelectConnection)
  const [contacts, setContacts] = useState<Tenant[]>()
  const [filteredContacts, setFilteredContacts] = useState<Tenant[]>([])
  const [selectedContact, setSelectedContact] = useState<{
    id: string
    name: string
  } | null>(null)
  const { projects, projectId, reload } = useAttributesContext()
  const [projectOptions, setProjectOptions] =
    useState<AttributeValueResponse[]>(projects)
  const [selectedProject, setSelectedProject] = useState<{
    id: string
    name: string
  } | null>(null)
  const [projectNameInput, setProjectNameInput] = useState<string>('')
  const [submitting, setSubmitting] = useState(false)
  const [tenantContactsLoading, setTenantContactsLoading] =
    useState<LoadingState>(LoadingState.Loading)
  const [sharedSpecificationSnapshot, setSharedSpecificationSnapshot] =
    useState<ShareSnapshotResponse>()

  useEffect(() => {
    const loadTenants = async () => {
      setTenantContactsLoading(LoadingState.Loading)
      try {
        const tenants = await getTenantContacts(userTenantId)
        setContacts(tenants)
        setFilteredContacts(tenants)
        setTenantContactsLoading(LoadingState.Loaded)
      } catch (error) {
        console.warn('Unable to load tenant contacts', error)
        setTenantContactsLoading(LoadingState.Failed)
      }
    }
    loadTenants()
  }, [userTenantId])

  const handleContactSearch = (query: string) => {
    if (!contacts) {
      setFilteredContacts([])
      return
    }

    const filtered = contacts.filter((contact) =>
      contact.name.toLowerCase().includes(query.toLowerCase()),
    )
    setFilteredContacts(filtered)
  }

  const onSelectContactChange = (contact: Tenant, selected: boolean) =>
    setSelectedContact(selected ? contact : null)

  const [projectSearchQuery, setProjectSearchQuery] = useState<string>('')
  const filteredProjects = useMemo(
    () =>
      projectOptions.filter((project) =>
        project.name.toLowerCase().includes(projectSearchQuery.toLowerCase()),
      ),
    [projectOptions, projectSearchQuery],
  )

  const onSelectProjectChange = (
    project: { id: string; name: string },
    selected: boolean,
  ) => setSelectedProject(selected ? project : null)

  const createProject = useCallback(async () => {
    if (!projectNameInput) {
      toastError('Project name expected', '')
      return
    }
    if (!projectId) {
      toastError('Unable to create project at this time', '')
      return
    }

    const tagColor = getRandomTagColor()
    try {
      const newProject = await attributesApi.createAttribute(projectId, {
        name: projectNameInput,
        metadata: {
          STYLES: {
            COLOR_FONT: tagColor.fontColor,
            COLOR_BG: tagColor.backgroundColor,
            COLOR_BG_HOVER: tagColor.hoverBackgroundColor || '',
          },
        },
      })

      setProjectOptions((prev) => [...prev, newProject])
      setSelectedProject(newProject)
      setProjectSearchQuery(newProject.name)
      setProjectNameInput('')
      reload()
    } catch (error) {
      toastError('Unable to create project at this time', '')
    }
  }, [projectId, projectNameInput, reload])

  const shareSpecification = useCallback(async () => {
    if (selectedContact === null) {
      toastError(
        'A connection must be selected to share this specification',
        '',
      )
      return
    }
    if (selectedProject === null) {
      toastError('A project must be selected to share this specification', '')
      return
    }

    setSubmitting(true)
    try {
      const sharedSpec = await api.shareSpecification(
        specification.id,
        selectedContact.id,
        selectedProject.id,
      )
      updateUserPermissions(
        PermissionEntityType.SpecificationSnapshots,
        sharedSpec.id,
        EntityRole.OWNER,
      )
      setSharedSpecificationSnapshot(sharedSpec)
      setStep(Step.Success)
    } catch (error) {
      console.error('Unable to share specification', error)
      toastError('Unable to share specification', 'Try again later')
    } finally {
      setSubmitting(false)
    }
  }, [
    selectedContact,
    selectedProject,
    specification.id,
    updateUserPermissions,
  ])

  return (
    <Modal
      title="Share specification"
      icon={<ShareKnowledge />}
      onClose={closeModal}
      innerRef={ref}
    >
      <div className={styles.content}>
        {step !== Step.Success && (
          <div className={styles.subtitle}>Instructions</div>
        )}
        {step === Step.Success && (
          <div className={styles.success}>Success!</div>
        )}
        <div className={styles.subcontent}>
          {step === Step.SelectConnection && (
            <>
              First, select who you want to share the specification with.
              <br />
              To connect with another organization, please reach out to your
              admin to establish the relationship on Stell.
            </>
          )}
          {step === Step.SelectProject && (
            <>
              {`Select the project this specification should be shared to. Alternatively, create a new project with your connection, ${selectedContact?.name}.`}
            </>
          )}
          {step === Step.Success && (
            <>
              <span>Your specification was shared with </span>
              <span className={styles.bold}>{selectedContact?.name}</span>
              <span>. View your data in your shared projects page.</span>
            </>
          )}
        </div>
        {step === Step.SelectConnection && (
          <div className={styles.selectList}>
            <input
              className={styles.input}
              placeholder="Type to find connections"
              onChange={(event) => handleContactSearch(event.target.value)}
            />
            <div className={styles.checkboxes}>
              {tenantContactsLoading === LoadingState.Loaded &&
                !contacts?.length && (
                  <div>
                    It looks like your organization does not have any external
                    connections on Stell.
                  </div>
                )}
              {tenantContactsLoading === LoadingState.Failed &&
                !contacts?.length && (
                  <div>Unable to display external connections.</div>
                )}
              {filteredContacts.map((contact) => (
                <div className={styles.option} key={contact.id}>
                  <div className={styles.text}>{contact.name}</div>
                  <Checkbox
                    checked={selectedContact?.id === contact.id}
                    onChange={(checked: boolean) =>
                      onSelectContactChange(contact, checked)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {step === Step.SelectProject && (
          <>
            <div className={`${styles.selectList} ${styles.subcontent}`}>
              <input
                className={styles.input}
                placeholder="Type to find projects"
                value={projectSearchQuery}
                onChange={(event) => setProjectSearchQuery(event.target.value)}
              />
              <div className={styles.checkboxes}>
                {filteredProjects.map((project) => (
                  <div className={styles.option} key={project.id}>
                    <div className={styles.text}>{project.name}</div>
                    <Checkbox
                      checked={selectedProject?.id === project.id}
                      onChange={(checked: boolean) =>
                        onSelectProjectChange(project, checked)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.subcontent}>
              <div className={styles.subtitle}>Add new project</div>
              <div className={styles.createProjectInput}>
                <input
                  className={styles.input}
                  placeholder="Project name"
                  value={projectNameInput}
                  onChange={(e) =>
                    setProjectNameInput(e.target.value.toUpperCase())
                  }
                  maxLength={20}
                />
                <button
                  onClick={() => createProject()}
                  className={`${styles.saveProjectBtn} ${
                    projectNameInput.length > 1 && styles.projectNameDefined
                  }`}
                >
                  <CheckmarkFilled />
                </button>
              </div>
            </div>
          </>
        )}
        <div className={styles.actions}>
          {(step === Step.SelectConnection || step === Step.SelectProject) && (
            <Button
              onClick={() => {
                step === Step.SelectConnection
                  ? setStep(Step.SelectProject)
                  : shareSpecification()
              }}
              text={
                step === Step.SelectConnection
                  ? 'Choose project'
                  : 'Share specification'
              }
              endIcon={
                <>
                  <NextOutline
                    className={
                      step === Step.SelectConnection ? '' : styles.hidden
                    }
                  />
                  <ShareKnowledge
                    className={step === Step.SelectProject ? '' : styles.hidden}
                  />
                </>
              }
              color={BUTTON_COLORS.PRIMARY}
              disabled={
                step === Step.SelectConnection
                  ? selectedContact === null
                  : submitting || selectedProject === null
              }
            />
          )}
          {step === Step.Success && sharedSpecificationSnapshot && (
            <Link
              to={`/workspaces/${sharedSpecificationSnapshot.workspaceId}/projects/${sharedSpecificationSnapshot.projectId}`}
              className={styles.link}
              onClick={() => closeModal()}
            >
              View shared projects
            </Link>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ShareSpecificationModal
