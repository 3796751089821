import { Save } from '@carbon/icons-react'
import { useCallback, useEffect, useState } from 'react'
import styles from './MyAccountSection.module.css'
import * as usersApi from '../../api/v2/users.ts'
import { UpdateUserRequest } from '../../api/v2/users.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import { toastError, toastSuccess } from '../../components/toast'
import { useAuth } from '../../context/AuthContext.tsx'
import { validatePassword } from '../../lib/password-validation.ts'

const MyAccountSection = () => {
  const { reload, userDetails } = useAuth()
  const { firstName, lastName, email, jobTitle } = userDetails || {}
  const [newFirstName, setNewFirstName] = useState(firstName || '')
  const [newLastName, setNewLastName] = useState(lastName || '')
  const [newEmail, setNewEmail] = useState(email || '')
  const [newJobTitle, setNewJobTitle] = useState(jobTitle || '')

  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [validPassword, setValidPassword] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  useEffect(() => {
    setNewFirstName(firstName || '')
    setNewLastName(lastName || '')
    setNewEmail(email || '')
    setNewJobTitle(jobTitle || '')
  }, [email, firstName, jobTitle, lastName, userDetails])

  const isValidUserDetails = useCallback(() => {
    if (!newFirstName && !newLastName && !newEmail && !newJobTitle) {
      return false
    }

    if (
      firstName === newFirstName &&
      lastName === newLastName &&
      email === newEmail &&
      jobTitle === newJobTitle
    ) {
      return false
    }

    return true
  }, [
    email,
    firstName,
    jobTitle,
    lastName,
    newEmail,
    newFirstName,
    newJobTitle,
    newLastName,
  ])

  useEffect(() => {
    if (!newPassword) {
      setValidPassword(false)
      setErrorMessages(['New password must be present'])
      return
    }

    const validation = validatePassword(newPassword)
    setValidPassword(validation.valid)
    setErrorMessages(validation.errorMessages || [])
  }, [newPassword, confirmPassword])

  const updateUser = useCallback(async () => {
    const newUserDetail: UpdateUserRequest = {
      firstName,
      lastName,
      email,
      jobTitle,
    }

    if (newFirstName) newUserDetail.firstName = newFirstName
    if (newLastName) newUserDetail.lastName = newLastName
    if (newEmail) newUserDetail.email = newEmail
    if (newJobTitle) newUserDetail.jobTitle = newJobTitle

    try {
      await usersApi.updateUser(newUserDetail)
      setNewFirstName(newUserDetail.firstName as string)
      setNewLastName(newUserDetail.lastName as string)
      setNewEmail(newUserDetail.email as string)
      setNewJobTitle(newUserDetail.jobTitle as string)

      reload()
      toastSuccess('User details successfully updated')
    } catch (e) {
      console.log('Failed to update user ', e)
      toastError(
        'Failed to update user details',
        'Please try again or refresh the page',
      )
    }
  }, [
    email,
    firstName,
    jobTitle,
    lastName,
    newEmail,
    newFirstName,
    newJobTitle,
    newLastName,
    reload,
  ])

  const updatePassword = useCallback(async () => {
    try {
      await usersApi.updatePassword(newPassword)
      toastSuccess('Password updated successfully')
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
    } catch (e) {
      toastError(
        'Failed to update password.',
        'Please try again or refresh the page',
      )
    }
  }, [newPassword])

  const displayedPasswordErrors = (newPassword && errorMessages) || []

  return (
    <div className={styles.myAccount}>
      <span>Manage your personal account settings.</span>
      <hr />
      <div className={styles.content}>
        <form>
          <div className={styles.profile}>
            <h2>Profile Details</h2>
            <label className={styles.label}>
              <span>First Name</span>
              <input
                placeholder={firstName}
                value={newFirstName}
                onChange={(e) => {
                  setNewFirstName(e.target.value)
                }}
              />
            </label>
            <label className={styles.label}>
              <span>Last Name</span>
              <input
                placeholder={lastName}
                value={newLastName}
                onChange={(e) => {
                  setNewLastName(e.target.value)
                }}
              />
            </label>
            <label className={styles.label}>
              <span>Email</span>
              <input
                disabled
                placeholder={email}
                value={newEmail}
                onChange={(e) => {
                  setNewEmail(e.target.value)
                }}
              />
            </label>
            <label className={styles.label}>
              <span>Job Title</span>
              <input
                placeholder={jobTitle}
                value={newJobTitle}
                onChange={(e) => {
                  setNewJobTitle(e.target.value)
                }}
              />
            </label>
            <Button
              className={styles.saveBtn}
              color={BUTTON_COLORS.PRIMARY}
              disabled={!isValidUserDetails()}
              onClick={(e) => {
                e.preventDefault()
                updateUser()
              }}
              text="Save"
              endIcon={<Save className={styles.saveIcon} />}
            />
          </div>
        </form>
        <form>
          <div className={styles.security}>
            <h2>Security</h2>
            <label className={styles.label}>
              <span>Current Password</span>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => {
                  setCurrentPassword(e.target.value)
                }}
              />
            </label>
            <label className={styles.label}>
              <span>New Password</span>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                }}
              />
            </label>
            <div className={styles.passwordErrors}>
              {displayedPasswordErrors.map((error) => (
                <div key={error}>{error}</div>
              ))}
            </div>

            <label className={styles.label}>
              <span>Confirm New Password</span>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                }}
              />
            </label>
            <div className={styles.passwordErrors}>
              {newPassword &&
                confirmPassword &&
                newPassword !== confirmPassword && (
                  <div>Confirmed password must match new password.</div>
                )}
              {currentPassword &&
                newPassword &&
                currentPassword === newPassword && (
                  <div>New password cannot match current password.</div>
                )}
            </div>

            <Button
              className={styles.savePassword}
              color={BUTTON_COLORS.PRIMARY}
              disabled={
                !currentPassword ||
                !newPassword ||
                !confirmPassword ||
                newPassword !== confirmPassword ||
                currentPassword === newPassword ||
                !validPassword
              }
              onClick={(e) => {
                e.preventDefault()
                updatePassword()
              }}
              text="Update Password"
              endIcon={<Save className={styles.saveIcon} />}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default MyAccountSection
