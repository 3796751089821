import { Close, Locked } from '@carbon/icons-react'
import styles from './Chip.module.css'

const TEXT = {
  status: {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED',
    REVIEW: 'IN REVIEW',
    SUPERSEDED: 'SUPERSEDED',
  },
}

const lockedStates = ['ACTIVE', 'REVIEW', 'SUPERSEDED']

const Chip = (props) => {
  const {
    className,
    variant = 'status',
    value,
    onRemove,
    enableLockIcon,
  } = props
  return (
    <div
      className={`  ${className || ''} ${styles.chip} ${
        styles[value.replace(/\s/g, '').toLowerCase()]
      }`}
    >
      {enableLockIcon && lockedStates.includes(value) && <Locked size={12} />}
      {TEXT[variant][value]}
      {onRemove && (
        <button onClick={onRemove}>
          <Close size={16} />
        </button>
      )}
    </div>
  )
}

export default Chip
